import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {environment} from '@environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Response} from '@coastal/types/response';
import {MomentumRoute} from 'app/types/route/momentumRoute';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  private momentumRoutes: MomentumRoute[];
  private newPath = '';
  private readonly _path = '/MomentumRoute';
  private cacheUpdated = false;
  private redirectAfterResponse = false;
  private initialPath = '';

  constructor(private router: Router, private _http: HttpClient) {
    this.momentumRoutes = JSON.parse(localStorage.getItem('momentumRoutes') ?? '[]').filter(
      (route: MomentumRoute) => !route.disableRedirect
    ) as MomentumRoute[];

    if (this.momentumRoutes.length > 0) {
      this.routeToUrl(window.location.pathname);
    }

    this.getRoutesFromApi().subscribe(r => {
      this.momentumRoutes = JSON.parse(JSON.stringify(r.data)).filter((route: MomentumRoute) => !route.disableRedirect) as MomentumRoute[];
      localStorage.setItem('momentumRoutes', JSON.stringify(this.momentumRoutes));
      this.cacheUpdated = true;
      this.setUpRouteListener();
      if (this.redirectAfterResponse && this.initialPath !== '') {
        this.router.navigateByUrl(this.initialPath);
    }
    });
  }

  setUpRouteListener(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.routeToUrl(event.url);
      }
    });
  }

  getRoutesFromApi(): Observable<Response<MomentumRoute[]>> {
    return this._http.get<Response<MomentumRoute[]>>(
      `${environment.apiUrl}${this._path}`);
  }

  routeToUrl(url: string): void {
    const requestedRoute = this.getRequestedRoute(url);

    if (this.routeHasRequest(requestedRoute) 
     && this.doesRouteExist(requestedRoute) 
     && environment.momentumUIRedirectEnabled === 'true') {

      if(this.cacheUpdated){

        window.location.href = environment.otherMomentumUrl + (this.newPath !== '' ? this.newPath : url);
        }else{
          this.initialPath = window.location.pathname;
          this.redirectAfterResponse = true;
          this.router.navigateByUrl('rll');
        }
      
    }
  }

  doesRouteExist(requestedRoute: MomentumRoute): boolean {
    for (const route of this.momentumRoutes) {
      if (this.compareRoutes(requestedRoute, route)) {
        this.newPath = route.newPath;
        return true;
      }
    }
    return false;
  }

  compareRoutes(requestedRoute: MomentumRoute, route: MomentumRoute): boolean {
    return (
      (requestedRoute.nav.toLocaleLowerCase() === route.nav.toLocaleLowerCase()  || route.nav === '*') &&
      (requestedRoute.subNav.toLocaleLowerCase() === route.subNav.toLocaleLowerCase() || route.subNav === '*') &&
      (requestedRoute.tabNav.toLocaleLowerCase() === route.tabNav.toLocaleLowerCase() || route.tabNav === '*') &&
      (route.lastNav == null || requestedRoute.lastNav?.toLocaleLowerCase() === route.lastNav?.toLocaleLowerCase() || route.lastNav === '*'));
  
  }

  getRequestedRoute(requestedPath: string): MomentumRoute {
    let navs = requestedPath.split('/').filter(Boolean);
    navs = navs.map(function(value) {
      const navWithSearchText = value.split(';');
      return navWithSearchText[0];
    });

    const navLength = navs.length;
    return {
      nav: navs[0] ?? '',
      subNav: navs[1] ?? '',
      tabNav: (navLength >= 3 ? navs[2] : null) ?? '',
      lastNav: (navLength > 3 ? navs[navLength - 1] : null) ?? '',
    };
  }

  routeHasRequest(route: MomentumRoute): boolean {
    return route.nav !== '' || route.subNav !== '' || route.tabNav !== '';
  }
}
