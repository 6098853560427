import { Injectable } from '@angular/core';
import { GeneralLedgerService } from './general-ledger/general-ledger.service';
import { GeneralLedgerAccountPlaceholderService } from './general-ledger-account-placeholder/general-ledger-account-placeholder.service';
import { TermPlanService } from './term-plan/term-plan.service';
import { AuctionService } from './auction/auction.service';
import { GeneralLedgerAccountTypeService } from './general-ledger-account-type/general-ledger-account-type.service';
import { GeneralLedgerReceivableTypeService } from './general-ledger-receivable-type/general-ledger-receivable-type-service';
import { LoanGroupService } from './loan-group/loan-group.service';
import { PaymentMethodService } from './payment-method/payment-method.service';
import { DisbursementService } from './disbursement/disbursement.service';
import { AutoAchService } from './auto-ach/autoAch.service';
import { SubLedgerService } from './bookkeeping/sub-ledger/sub-ledger.service';
import { BookkeepingService } from './bookkeeping/bookkeeping.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
  constructor(
    private _auctionService: AuctionService,
    private _disbursementService: DisbursementService,
    private _generalLedgerService: GeneralLedgerService,
    private _generalLedgerAccountPlaceholderService: GeneralLedgerAccountPlaceholderService,
    private _termPlanService: TermPlanService,
    private _generalLedgerAccountTypeService: GeneralLedgerAccountTypeService,
    private _generalLedgerReceivableTypeService: GeneralLedgerReceivableTypeService,
    private _loanGroupService: LoanGroupService,
    private _paymentMethodService: PaymentMethodService,
    private _autoAchService: AutoAchService,
    private _subLedgerService: SubLedgerService,
    private _bookkeepingService: BookkeepingService
    ) { }

  auction = this._auctionService;
  disbursement = this._disbursementService;
  generalLedger = this._generalLedgerService;
  generalLedgerAccountPlaceholder = this._generalLedgerAccountPlaceholderService;
  termPlan = this._termPlanService;
  generalLedgerAccountType = this._generalLedgerAccountTypeService;
  generalLedgerReceivableTypeService = this._generalLedgerReceivableTypeService;
  loanGroup = this._loanGroupService;
  paymentMethod = this._paymentMethodService;
  autoAch = this._autoAchService;
  subLedgerService = this._subLedgerService;
  bookkeepingService = this._bookkeepingService;
}
