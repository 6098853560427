import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BusyOverlayComponent } from './busy-overlay.component';

@NgModule({
    declarations: [BusyOverlayComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  exports: [BusyOverlayComponent]
})
export class BusyOverlayModule { }
