import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Fee } from 'app/types/shared/fee';
import { TransactionHistory } from 'app/types/shared/transactionHistory';
import { PaymentOptions } from 'app/types/shared/paymentOptions';
import { PaymentRequest } from 'app/types/shared/paymentRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { TransactionHistoryDetailSearchRequest } from 'app/types/shared/transactionHistoryDetailSearchRequest';
import { TransactionHistoryDetail } from 'app/types/shared/transactionHistoryDetail';
import { FeeTransactionHistory as FeeTransactionHistory } from 'app/types/shared/feeTransactionHistory';
import { PaymentOptionsRequest } from 'app/types/accounting/payment/paymentOptionsRequest';

@Injectable({
  providedIn: 'root'
})
export class TransactionHistoryService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/transactionHistory';
  }

  addDealerFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/add/dealer`, fee);
  }

  addAuctionFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/add/auction`, fee);
  }

  addFloorplanFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/add/floorplan`, fee);
  }

  voidDealerFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/dealer`, fee);
  }

  voidAuctionFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/auction`, fee);
  }

  voidFloorplanFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/floorplan`, fee);
  }

  waiveDealerFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/waive/dealer`, fee);
  }

  waiveAuctionFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/waive/auction`, fee);
  }

  waiveFloorplanFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/waive/floorplan`, fee);
  }

  getPaymentOptionsForDealer(request: PaymentOptionsRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment-options/dealer/`, request);
  }

  getPaymentOptionsForFloorplan(request: PaymentOptionsRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment-options/floorplan/`, request);
  }

  getPaymentOptionsForAuction(request: PaymentOptionsRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment-options/auction/`, request);
  }

  makePaymentFromDealer(request: PaymentRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment/dealer`, request);
  }

  makePaymentFromAuction(request: PaymentRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment/auction`, request);
  }

  makePaymentFromFloorplan(request: PaymentRequest): Observable<Response<PaymentOptions>> {
    return this._http.post<Response<PaymentOptions>>(`${environment.apiUrl}${this.path}/payment/floorplan`, request);
  }

  searchTransactionHistoryDetailService(request: TransactionHistoryDetailSearchRequest): Observable<Response<PagedSearchResults<TransactionHistoryDetail>>> {
    return this._http.post<Response<PagedSearchResults<TransactionHistoryDetail>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  reverseVoidedDealerFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/reverse/dealer`, fee);
  }

  reverseVoidedAuctionFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/reverse/auction`, fee);
  }

  reverseVoidedFloorplanFee(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/void/reverse/floorplan`, fee);
  }

  getUnappliedFundsTransactionsForDealer(dealerId: string): Observable<Response<TransactionHistory[]>> {
    return this._http.get<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/unappliedFunds/dealer/${dealerId}`);
  }

  getUnappliedFundsTransactionsForAuction(auctionId: string): Observable<Response<TransactionHistory[]>> {
    return this._http.get<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/unappliedFunds/auction/${auctionId}`);
  }

  reverseWaiveFeeAsync(fee: Fee): Observable<Response<TransactionHistory[]>> {
    return this._http.post<Response<TransactionHistory[]>>(`${environment.apiUrl}${this.path}/waive/reverse`, fee);
  }

  getTransactionHistoryForDealer(dealerId: string): Observable<Response<FeeTransactionHistory[]>> {
    return this._http.get<Response<FeeTransactionHistory[]>>(`${environment.apiUrl}${this.path}/fees/dealer/${dealerId}`);
  }

  getTransactionHistoryForFloorplan(floorplanId: string): Observable<Response<FeeTransactionHistory[]>> {
    return this._http.get<Response<FeeTransactionHistory[]>>(`${environment.apiUrl}${this.path}/fees/floorplan/${floorplanId}`);
  }

  getTransactionHistoryForAuction(auctionId: string): Observable<Response<FeeTransactionHistory[]>> {
    return this._http.get<Response<FeeTransactionHistory[]>>(`${environment.apiUrl}${this.path}/fees/auction/${auctionId}`);
  }
}
