import { LoggingLevel } from '@coastal/enums/loggingLevel';
import { AuthConfig } from 'angular-oauth2-oidc';

function getEnvironment() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const environment = (window as any).environment;

  const theme = environment.theme;
  environment.theme = hasBeenReplaced(theme) ? theme : 'theme-teal';
  environment.loggingLevel = LoggingLevel[environment.loggingLevel];
  environment.AuthConfig = new AuthConfig(environment.AuthConfig);

  return environment;
}

export const environment = getEnvironment();

function hasBeenReplaced(variable: string) {
  return !RegExp(/#{.*}#/).test(variable);
}
