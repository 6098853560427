import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { UnappliedFunds } from 'app/types/dealer/unappliedFunds';

@Injectable({
  providedIn: 'root'
})
export class UnappliedFundsHistoryService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/unappliedfundS';
  }

  getDealer(responsiblePartyId: string): Observable<Response<UnappliedFunds[]>> {
    return this._http.get<Response<UnappliedFunds[]>>(`${environment.apiUrl}${this.path}/dealer/${responsiblePartyId}`);
  }

  getAuction(responsiblePartyId: string): Observable<Response<UnappliedFunds[]>> {
    return this._http.get<Response<UnappliedFunds[]>>(`${environment.apiUrl}${this.path}/auction/${responsiblePartyId}`);
  }

}
