import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { UpdateAttentionLineRequest } from 'app/types/shared/UpdateAttentionLineRequest';
import { TitleReleaseAddress } from 'app/types/shared/titleReleaseAddress';
import { ValidatedAddress } from 'app/types/shared/validatedAddress';
import { ReleaseTitleAddressRequest } from 'app/types/floorplan/releaseTitleAddressRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { TitleSearchRequest } from 'app/types/titles/titleSearchRequest';
import { TitleReleaseBusinessAddressSearchResult } from 'app/types/titles/titleReleaseBusinessAddressSearchResult';

@Injectable({
  providedIn: 'root'
})
export class TitleReleaseAddressService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/title-release-address';
  }

  updateAttentionLine(request: UpdateAttentionLineRequest): Observable<TitleReleaseAddress> {
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/attentionLine`, request);
  }

  inactivateTitleReleaseAddress(titleReleaseAddressId: string): Observable<TitleReleaseAddress> {
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/inactivate/${titleReleaseAddressId}`, {});
  }

  activateTitleReleaseAddress(titleReleaseAddressId: string): Observable<TitleReleaseAddress> {
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/activate/${titleReleaseAddressId}`, {});
  }

  setDefaultTitleReleaseAddress(titleReleaseAddressId: string): Observable<TitleReleaseAddress[]> {
    return this._http.put<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/default/${titleReleaseAddressId}`, {});
  }

  addTitleReleaseAddress(titleReleaseAddress: TitleReleaseAddress): Observable<TitleReleaseAddress[]> {
    return this._http.post<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/add`, titleReleaseAddress);
  }

  addTitleReleaseBusinessAddress(titleReleaseAddress: TitleReleaseAddress): Observable<TitleReleaseAddress[]> {
    return this._http.post<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/add-title-release-business-address`, titleReleaseAddress);
  }

  getAuctionTitleReleaseAddresses(auctionId: string): Observable<TitleReleaseAddress[]> {
    return this._http.get<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/auction/${auctionId}`);
  }

  getDealerTitleReleaseAddresses(dealerId: string): Observable<TitleReleaseAddress[]> {
    return this._http.get<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/dealer/${dealerId}`);
  }

  getTitleReleaseAddressesByListOfDealerIds(dealerIds: string[]): Observable<TitleReleaseAddress> {
    return this._http.post<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/by-dealers-id-list/`, dealerIds);
  }

  getTitleReleaseAddressByValidatedAddressId(validatedAddressId: string): Observable<TitleReleaseAddress> {
    return this._http.get<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/title-release-address/${validatedAddressId}`);
  }

  getTitleReleaseAddressesByListOfValidatedAddressIds(validatedAddressIds: string[]): Observable<TitleReleaseAddress[]> {
    return this._http.post<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/by-validated-address-id-list`, validatedAddressIds);
  }

  updateTitleReleaseAddress(titleReleaseAddress: TitleReleaseAddress): Observable<TitleReleaseAddress>{
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/update-title-release-address`, titleReleaseAddress);
  }

  addValidatedAddress(validatedAddress: ValidatedAddress): Observable<ValidatedAddress> {
    return this._http.post<ValidatedAddress>(`${environment.apiUrl}${this.path}/validated-address/add`, validatedAddress);
  }

  deleteValidatedAddress(validatedAddressId: string): Observable<ValidatedAddress> {
    return this._http.delete<ValidatedAddress>(`${environment.apiUrl}${this.path}/delete-validated-address/${validatedAddressId}`);
  }

  getAllTitleReleaseAddressesByTitleLocation(titleLocationId: string): Observable<TitleReleaseAddress[]> {
    return this._http.get<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/by-title-location/${titleLocationId}`);
  }

  getAddressesByCategoryAndEntity(request: ReleaseTitleAddressRequest): Observable<TitleReleaseAddress[]> {
    return this._http.post<TitleReleaseAddress[]>(`${environment.apiUrl}${this.path}/category-and-entity`, request);
  }

  getExistingAddressByUniqueness(validatedAddress: ValidatedAddress): Observable<ValidatedAddress> {
    return this._http.post<ValidatedAddress>(`${environment.apiUrl}${this.path}/get-by-uniqueness`, validatedAddress);
  }

  getTitleReleaseBusinessAddresses(request: TitleSearchRequest): Observable<Response<PagedSearchResults<TitleReleaseBusinessAddressSearchResult>>> {
    return this._http.post<Response<PagedSearchResults<TitleReleaseBusinessAddressSearchResult>>>(`${environment.apiUrl}${this.path}/search-title-release-business-addresses`, request);
  }

  checkForDuplicateAddresses(titleReleaseAddress: TitleReleaseAddress): Observable<Response<TitleReleaseAddress[]>>{
    return this._http.post<Response<TitleReleaseAddress[]>>(`${environment.apiUrl}${this.path}/check-for-duplicate-addresses`, titleReleaseAddress);
  }

  inactivateTitleReleaseBusinessAddress(titleReleaseAddressId: string): Observable<TitleReleaseAddress> {
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/inactivate-title-release-business-address/${titleReleaseAddressId}`, {});
  }

  activateTitleReleaseBusinessAddress(titleReleaseAddressId: string): Observable<TitleReleaseAddress> {
    return this._http.put<TitleReleaseAddress>(`${environment.apiUrl}${this.path}/activate-title-release-business-address/${titleReleaseAddressId}`, {});
  }
}
