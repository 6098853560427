import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GeneralLedgerAccount } from 'app/types/accounting/general-ledger/generalLedgerAccount';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';

import { environment } from '@environment';
import { Response } from '@coastal/types/response';

@Injectable({
  providedIn: 'root'
})
export class GeneralLedgerService {
  private readonly _path = '/generalledgeraccount';

  constructor(private _http: HttpClient) {}

  save(generalLedgerAccount: GeneralLedgerAccount): Observable<Response<GeneralLedgerAccount>> {
    return this._http.post<Response<GeneralLedgerAccount>>(`${environment.apiUrl}${this._path}`, generalLedgerAccount);
  }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<GeneralLedgerAccount>>> {
    return this._http.post<Response<PagedSearchResults<GeneralLedgerAccount>>>(`${environment.apiUrl}${this._path}/search`, request);
  }
}
