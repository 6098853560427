import { Injectable } from '@angular/core';
import { LoggingService } from '@coastal';
import * as signalR from '@microsoft/signalr';
import { environment } from '@environment';
import { FloorplanVoidedMessage } from 'app/types/events/floorplanVoidedMessage';
import { ReplaySubject } from 'rxjs';
import { Floorplan } from 'app/types/floorplan/floorplan';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TitleRelease } from 'app/types/shared/titleRelease';
import { ApiService } from 'app/services/api/api.service';
import { FloorplanTitleReleaseMessage } from 'app/types/events/floorplanTitleReleaseMessage';
import { FloorplanTitleCancelReleaseMessage } from 'app/types/events/floorplanTitleCancelReleaseMessage';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private connection: signalR.HubConnection;
  public updatedTitleRelease: ReplaySubject<TitleRelease> = new ReplaySubject<TitleRelease>(1);
  public updatedFloorplan: ReplaySubject<Floorplan> = new ReplaySubject<Floorplan>(1);

  constructor(protected loggingService: LoggingService, private _snackBar: MatSnackBar, protected apiService: ApiService) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/events`, {
        accessTokenFactory: () => localStorage.getItem('darkhorse')
      })
      .build();
  }

  async initialize() {
    this.loggingService.debug('initialize');

    this.connection.on('FloorplanVoided', (message: FloorplanVoidedMessage) => {
      this.loggingService.debug('FloorplanVoided event received.');

      const notification = message.successful ? 'Your request to void this floorplan was successful.' : 'Your request to void this floorplan has failed.';

      if (message.successful) {
        this.apiService.floorplan
          .getFloorplan(message.floorplanId)
          .toPromise()
          .then(response => {
            this.updatedFloorplan.next(response.data);
          });
      }

      this._snackBar.open(notification, 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });

    this.connection.on('FloorplanTitleRelease', (floorplanTitleReleaseMessage: FloorplanTitleReleaseMessage) => {
      this.loggingService.debug('FloorplanTitleRelease event received.');

      const notification = floorplanTitleReleaseMessage.successful ? floorplanTitleReleaseMessage.errorMessage : 'Your request to release this title has failed. ' +
        floorplanTitleReleaseMessage.errorMessage;

      if (!floorplanTitleReleaseMessage.successful) {
        this.apiService.floorplan
          .getFloorplan(floorplanTitleReleaseMessage.floorplanId)
          .toPromise()
          .then(response => {
            this.updatedFloorplan.next(response.data);
          });
      }

      this._snackBar.open(notification, 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });

    this.connection.on('FloorplanTitleCancelRelease', (message: FloorplanTitleCancelReleaseMessage) => {
      this.loggingService.debug('FloorplanTitleCancelRelease event received.');

      const notification = message.successful ? 'Your request to cancel this title release was successful.' : 'Your request to cancel this title release has failed. '
        + message.errorMessage;

      if (message.successful) {
        this.apiService.floorplan
          .getFloorplan(message.floorplanId)
          .toPromise()
          .then(response => {
            this.updatedFloorplan.next(response.data);
          });
      }

      this._snackBar.open(notification, 'Dismiss', {
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });

    this.connection.on('Broadcast', message => {
      this.loggingService.debug(`Broadcast: ${message}`);
    });

    return this.connection.start().catch(reason => this.loggingService.error(reason));
  }
}
