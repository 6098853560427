import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Setting } from 'app/types/shared/application/setting';
import { SettingValue } from 'app/types/shared/application/settingValue';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly _path = '/setting';

  constructor(private _http: HttpClient) { }

  getAll(): Observable<Response<any>> {
    return this._http.get<Response<any>>(`${environment.apiUrl}${this._path}/frontEndSettings`);
  }

  getAllSettings(): Observable<Response<any>> {
    return this._http.get<Response<any>>(`${environment.apiUrl}${this._path}`);
  }

  get(settingId: string): Observable<Response<Setting>> {
    return this._http.get<Response<Setting>>(`${environment.apiUrl}${this._path}/${settingId}`);
  }

  getBaseRate(): Promise<Setting> {
    return this._http.get<Setting>(`${environment.apiUrl}${this._path}/base-rate/`)
      .toPromise();
  }

  addNextBaseRate(settingValue: SettingValue): Promise<Setting> {
    return this._http.post(`${environment.apiUrl}${this._path}/add-next-base-rate`, settingValue)
      .toPromise() as Promise<Setting>;
  }
}
