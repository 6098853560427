import { Injectable } from '@angular/core';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { UpdateRolesForSecurityGroup } from 'app/types/security/user-model/updateRolesForSecurityGroup';
import { Permission } from 'app/types/security/user-model/permission';
import { Role } from 'app/types/security/user-model/role';
import { UpdatePermissionsForSecurityGroup } from 'app/types/security/user-model/updatePermissionsForSecurityGroup';
import { SecurityGroup } from 'app/types/security/user-model/securityGroup';
import { UpdateUsersForSecurityGroup } from 'app/types/security/user-model/updateUsersForSecurityGroup';

@Injectable({
  providedIn: 'root',
})
export class UserManagementSelectionGroupsService {
  readonly path = '/securitygroup';

  constructor(private _http: HttpClient) { }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<SecurityGroup>>> {
    return this._http.post<Response<PagedSearchResults<SecurityGroup>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  getSecurityGroup(securityGroupId: string): Observable<Response<SecurityGroup>> {
    return this._http.get<Response<SecurityGroup>>(`${environment.apiUrl}${this.path}/detail/` + securityGroupId);
  }

  getAllSecurityGroups(): Observable<Response<SecurityGroup[]>> {
    return this._http.get<Response<SecurityGroup[]>>(`${environment.apiUrl}${this.path}/getall`);
  }

  saveSecurityGroup(group: SecurityGroup): Observable<Response<SecurityGroup>> {
    return this._http.post<Response<SecurityGroup>>(`${environment.apiUrl}${this.path}`, group );
  }

  saveUsersForSecurityGroup(updateUsersForSecurityGroup: UpdateUsersForSecurityGroup): Observable<Response<SecurityGroup>> {
    return this._http.post<Response<SecurityGroup>>(`${environment.apiUrl}${this.path}/updateUsers/`, updateUsersForSecurityGroup);
  }

  saveRolesForSecurityGroup(updateRolesForSecurityGroup: UpdateRolesForSecurityGroup): Observable<Response<Role>> {
    return this._http.post<Response<Role>>(`${environment.apiUrl}${this.path}/updateRoles/`, updateRolesForSecurityGroup);
  }

  savePermissionsForSecurityGroup(updatePermissionsForSecurityGroup: UpdatePermissionsForSecurityGroup): Observable<Response<Permission>> {
    return this._http.post<Response<Permission>>(`${environment.apiUrl}${this.path}/updatePermissions/`, updatePermissionsForSecurityGroup);
  }
}
