export class BusinessType {
    public static readonly 'Auction': BusinessType = {
        businessTypeId: '52F14AC9-C0C8-4F19-9599-BEA93439E073',
        name: 'Auction'
    };

    public static readonly 'Dealer': BusinessType = {
        businessTypeId: '21602051-2DC0-436B-A942-D8EE610CBB6C',
        name: 'Dealer'
    };

    businessTypeId?: string;
    name: string;

    public static getValues() {
        return [
            this.Auction,
            this.Dealer
        ];
    }
}
