import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environment';
import { Market } from 'app/types/shared/market';

@Injectable({
  providedIn: 'root'
})
export class MarketService {
  readonly path = '/market';

  marketData: BehaviorSubject<Market[]> = new BehaviorSubject<Market[]>(null);

  constructor(private _http: HttpClient) {}

  getAll(): Observable<Market[]> {
    return this._http.get<Market[]>(`${environment.apiUrl}${this.path}/all`);
  }

  getAllActive(): Observable<Market[]> {
    return this._http.get<Market[]>(`${environment.apiUrl}${this.path}/allActive`);
  }

  addMarketRegion(market: Market): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}${this.path}/addMarketRegion`, market );
  }

  editMarketRegion(market: Market): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}${this.path}/editMarketRegion`, market);
  }
}
