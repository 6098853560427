import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { environment } from '@environment';
import { GetByFeeApplicableTypeRequest } from 'app/fees/fee-modal/getByFeeApplicableTypeRequest';
import { GeneralLedgerReceivableType } from 'app/types/accounting/general-ledger/generalLedgerReceivableType';

@Injectable({
  providedIn: 'root'
})
export class GeneralLedgerReceivableTypeService {
  private readonly _path = '/generalledgerreceivabletype';

  constructor(private _http: HttpClient) {}

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<GeneralLedgerReceivableType>>> {
    return this._http.post<Response<PagedSearchResults<GeneralLedgerReceivableType>>>(`${environment.apiUrl}${this._path}/search`, request);
  }

  edit(generalLedgerReceivableType: GeneralLedgerReceivableType): Observable<Response<GeneralLedgerReceivableType>> {
    return this._http.post<Response<GeneralLedgerReceivableType>>(`${environment.apiUrl}${this._path}/edit`, generalLedgerReceivableType);
  }

  add(generalLedgerReceivableType: GeneralLedgerReceivableType): Observable<Response<GeneralLedgerReceivableType>> {
    return this._http.post<Response<GeneralLedgerReceivableType>>(`${environment.apiUrl}${this._path}/add`, generalLedgerReceivableType);
  }

  getByFeeApplicableTypeAsync(feeApplicableTypeId: string, dealerProgramId: string): Observable<Response<GeneralLedgerReceivableType[]>> {
    return this._http.get<Response<GeneralLedgerReceivableType[]>>(`${environment.apiUrl}${this._path}/getFeeApplicableType/${feeApplicableTypeId}/${dealerProgramId}`);
  }

  getByFeeApplicableTypeNoProgramIdAsync(feeApplicableTypeId: string): Observable<Response<GeneralLedgerReceivableType[]>> {
    return this._http.get<Response<GeneralLedgerReceivableType[]>>(`${environment.apiUrl}${this._path}/getFeeApplicableType/${feeApplicableTypeId}`);
  }
}
