import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptDialogComponent } from './components/prompt-dialog/prompt-dialog.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';


@NgModule({
  declarations: [
    PromptDialogComponent,
    NotificationDialogComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FlexLayoutModule,
  ]
})
export class CoastalSharedModule { }
