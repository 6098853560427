import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { DealerAuditHistory } from 'app/types/dealer/lot-audit/twelve-tech/dealerAuditHistory';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { DealerAuditDetail } from 'app/types/dealer/lot-audit/twelve-tech/dealerAuditDetail';

@Injectable({
  providedIn: 'root'
})
export class AuditService extends BreadcrumbService {
  private readonly path = '/audit';

  constructor(private _http: HttpClient) {
    super();
  }

  getDealerAuditHistory(dealerId: string): Observable<Response<DealerAuditHistory[]>> {
    return this._http.get<Response<DealerAuditHistory[]>>(`${environment.apiUrl}${this.path}/get-dealer-audit-history/${dealerId}`);
  }

  getDealerAuditDetails(auditMasterId: string): Observable<Response<DealerAuditDetail[]>> {
    return this._http.get<Response<DealerAuditDetail[]>>(`${environment.apiUrl}${this.path}/get-dealer-audit-details/${auditMasterId}`);
  }
}
