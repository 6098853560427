
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { Action } from 'app/types/action';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { NewInsuranceDetails } from 'app/types/dealer/newInsuranceDetails';
import { InsuranceSetUp } from 'app/types/shared/insuranceSetUp';
import { Insurance } from 'app/types/shared/insurance';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { SupportsFiltering } from '@coastal/types/supportsFiltering';
import { InsuranceProgram } from 'app/types/insurance/insuranceProgram';
import { InsuranceProgramSearchRow } from 'app/types/insurance/insuranceProgramSearchRow';
import { DealerInsurance } from 'app/types/dealer/dealerInsurance';
import { KineticInsurance } from 'app/types/dealer/kineticInsurance';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService extends BreadcrumbService {
  private readonly path = '/insurance';
  private insuranceInfoReadOnly = new Subject<boolean>();
  private actions = new BehaviorSubject <Action[]>([]);

  constructor(private _http: HttpClient) {
    super();
  }

  addInsurance(details: NewInsuranceDetails): Observable<Response<string>> {
    return this._http.post<Response<string>>(`${environment.apiUrl}${this.path}/add`, details);
  }

  getInsurance(id: string): Observable<Response<Insurance>> {
    return this._http.get<Response<Insurance>>(`${environment.apiUrl}${this.path}/${id}`);
  }

  getInsuranceSetUpByState(stateId: string): Observable<Response<InsuranceSetUp[]>> {
    return this._http.get<Response<InsuranceSetUp[]>>(`${environment.apiUrl}${this.path}/insurancesetup/${stateId}`);
  }

  getDealerInsurance(dealerId: string): Observable<Response<DealerInsurance[]>> {
    return this._http.get<Response<DealerInsurance[]>>(`${environment.apiUrl}${this.path}/dealerinsurance/${dealerId}`);
  }

  getKineticInsurance(dealerId: string): Observable<Response<KineticInsurance[]>> {
    return this._http.get<Response<KineticInsurance[]>>(`${environment.apiUrl}${this.path}/kineticinsurance/${dealerId}`);
  }

  updateInsurance(insurance: Insurance): Observable<Response<Insurance>> {
    return this._http.put<Response<Insurance>>(`${environment.apiUrl}${this.path}/update`, insurance);
  }

  searchInsurancePrograms(request: PagedSearchRequest | SupportsFiltering): Observable<Response<PagedSearchResults<InsuranceProgramSearchRow>>> {
    return this._http.post<Response<PagedSearchResults<InsuranceProgramSearchRow>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  addInsuranceProgram(newInsurance: InsuranceProgram): Observable<Response<string>> {
    return this._http.post<Response<string>>(`${environment.apiUrl}${this.path}/create`, newInsurance);
  }

  getInsuranceProgram(id: string): Observable<Response<InsuranceProgram>> {
    return this._http.get<Response<InsuranceProgram>>(`${environment.apiUrl}${this.path}/insuranceProgram/${id}`);
  }
}
