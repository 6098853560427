import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breadcrumb } from 'app/types/shared/breadcrumb';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs = new BehaviorSubject <Breadcrumb[]>([]);

  constructor(
  ) { }

  updateBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs.next(breadcrumbs);
  }

  setBreadcrumbsToBase() {
    const currentBreadcrumbs = this.breadcrumbs.getValue();
    if (currentBreadcrumbs.length > 1)
      this.updateBreadcrumbs([currentBreadcrumbs[0]]);
  }

  clearBreadcrumbs() {
    this.breadcrumbs.next([]);
  }

  AddBreadCrumbs(newBreadcrums: Breadcrumb[]) {
    const currentBreadcrumbs = this.breadcrumbs.getValue();
    currentBreadcrumbs.push(...newBreadcrums);
    this.updateBreadcrumbs(currentBreadcrumbs);
  }

  getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this.breadcrumbs.asObservable();
  }
}
