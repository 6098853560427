import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { User } from 'app/types/security/user-model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly _path = '/user';

  constructor(private _http: HttpClient) {}

  get(userId: string): Observable<Response<User>> {
    return this._http.get<Response<User>>(
      `${environment.apiUrl}${this._path}/username/${userId}`
    );
  }

  logout(username: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(
      `${environment.apiUrl}${this._path}/logout/${username}`
    );
  }

  getAll(): Observable<any> {
    return this._http.get<any>(`${environment.apiUrl}${this._path}/GetAll`);
  }

  getAllUsersByRole(roleName: string): Observable<Response<User[]>> {
    return this._http.get<Response<User[]>>(
      `${environment.apiUrl}${this._path}/GetUsersByRole/${roleName}`
    );
  }

  getAllUsersByRoles(roleNames: string[]): Observable<Response<User[]>> {
    return this._http.post<Response<User[]>>(
      `${environment.apiUrl}${this._path}/GetUsersByRoles/`, roleNames
    );
  }
}
