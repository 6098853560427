import { Directive, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][taxIdMask]',
})
export class TaxIdMaskDirective implements OnInit {

  constructor(public ngControl: NgControl) { }

  ngOnInit() {
    this.formatValue(this.ngControl.value);
  }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.formatValue(event);
  }

  formatValue(event) {
    let newVal;
    if (event) {
      newVal = event.replace(/\D/g, '');
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length <= 2) {
        newVal = newVal.replace(/^(\d{0,2})/, '$1');
      } else if (newVal.length <= 9) {
        newVal = newVal.replace(/^(\d{0,2})(\d{0,7})/, '$1-$2');
      } else {
        newVal = newVal.substring(0, 9);
        newVal = newVal.replace(/^(\d{0,2})(\d{0,7})/, '$1-$2');
      }
    }
    this.ngControl.control?.setValue(newVal, { emitViewToModelChange: false});
  }
}
