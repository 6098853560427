import { Injectable, Optional } from '@angular/core';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class OAuthInterceptor implements HttpInterceptor {

    constructor(
        private authStorage: OAuthStorage,
        private errorHandler: OAuthResourceServerErrorHandler,
        private _http: HttpClient,
        @Optional() private moduleConfig: OAuthModuleConfig
    ) {}

    private checkUrl(url: string): boolean {
        const found = this.moduleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
        return !!found;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const url = req.url.toLowerCase();
        if (!this.moduleConfig) return next.handle(req);
        if (!this.moduleConfig.resourceServer) return next.handle(req);
        if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);
        if (!this.checkUrl(url)) return next.handle(req);
        const sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken;
        const useDarkhorseToken = url.includes(environment.apiUrl) || url.includes(environment.authenticationApiUrl) || url.includes(environment.assetReportApiUrl);

        if (sendAccessToken) {
          const token = useDarkhorseToken ? this.authStorage.getItem('darkhorse') : this.authStorage.getItem('access_token');
          const header = 'Bearer ' + token;
          const headers = req.headers.set('Authorization', header);
          req = req.clone({ headers });
        }

        if (useDarkhorseToken && Date.now() > Number(this.authStorage.getItem('darkhorse_expires_at'))) {
          return this.requestDarkhorseRefresh(req, next);
        } else {
          return next.handle(req).catch(err => {
            return this.errorHandler.handleError(err);
          });
        }
    }

    requestDarkhorseRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
      const params = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('scope', environment.azureApplicationIdUri)
      .set('refresh_token', this.authStorage.getItem('darkhorse_refresh'))
      .set('client_id', environment.AuthConfig.clientId);

      const refreshHeaders = new HttpHeaders().set(
        'Content-Type',
        'application/x-www-form-urlencoded'
      );

      return this._http.post(environment.AuthConfig.tokenEndpoint,  params, { headers: refreshHeaders})
        .pipe(mergeMap((result: any) => {
          if (!result.error) {
            // Update tokens
            this.authStorage.setItem('darkhorse', result.access_token);
            const lifeTimeInSeconds = result.expires_in;
            this.authStorage.setItem('darkhorse_expires_at', (Date.now() + (lifeTimeInSeconds * 1000)).toString());
            this.authStorage.setItem('darkhorse_refresh', result.refresh_token);
            // update request wiht new access token
            const header = 'Bearer ' + result.access_token;
            const headers = req.headers
                                .set('Authorization', header);
            req = req.clone({ headers });
            return next.handle(req).catch(err2 => {
              return this.errorHandler.handleError(err2);
            });
        } else {
          return next.handle(req).catch(err => {
            return this.errorHandler.handleError(err);
          });
        }
      }));
    }
}
