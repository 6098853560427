import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { FloorplanAuditDetail } from 'app/types/floorplan/lot-audit/twelve-tech/floorplanAuditDetail';

@Injectable({
  providedIn: 'root'
})
export class FloorplanAuditService extends BreadcrumbService {
  private readonly path = '/audit';

  constructor(private _http: HttpClient) {
    super();
  }

  getFloorplanAuditDetails(floorplanCollateralId: string): Observable<Response<FloorplanAuditDetail[]>> {
    return this._http.get<Response<FloorplanAuditDetail[]>>(`${environment.apiUrl}${this.path}/get-floorplan-audit-details/${floorplanCollateralId}`);
  }
}
