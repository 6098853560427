import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Banner } from 'app/types/shared/banner';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { BannerSearchResult } from 'app/types/banner/bannerSearchResult';
import { BannerSearchRequest } from 'app/types/banner/bannerSearchRequest';


@Injectable({
  providedIn: 'root'
})
export class BannerService {

  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/banner';
  }

  getBanners(request: BannerSearchRequest): Observable<Response<PagedSearchResults<BannerSearchResult>>> {
    return this._http.post<Response<PagedSearchResults<BannerSearchResult>>>(`${environment.apiUrl}${this.path}/search-banners`, request);
  }

  getBanner(bannerId: string): Observable<Response<Banner>>{
    return this._http.get<Response<Banner>>(`${environment.apiUrl}${this.path}/${bannerId}`);
  }
  
  inactivateBanner(bannerId: string): Observable<Banner> {
    return this._http.put<Banner>(`${environment.apiUrl}${this.path}/inactivate-banner/${bannerId}`, {});
  }

  activateBanner(bannerId: string): Observable<Banner> {
    return this._http.put<Banner>(`${environment.apiUrl}${this.path}/activate-banner/${bannerId}`, {});
  }

  addBanner(banner: Banner): Observable<Banner> {
    return this._http.post<Banner>(`${environment.apiUrl}${this.path}/add-banner`, banner);
  }
  
  editBanner(banner: Banner):Observable<Response<Banner>> {
    return this._http.post<Response<Banner>>(`${environment.apiUrl}${this.path}/edit`, banner);
  }
}
