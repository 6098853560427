import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { PaymentSearchRequest } from 'app/types/shared/paymentSearchRequest';
import { PaymentHistory } from 'app/types/shared/paymentHistory';
import { PaymentVoidRequest } from 'app/types/shared/paymentVoidRequest';
import { PaymentSummary } from 'app/types/shared/paymentSummary';
import { VoidOrWaivePaymentBatchRequest } from 'app/types/accounting/payment/voidOrWaivePaymentBatchRequest';
import { PaymentToVoidOrTransfer } from 'app/types/accounting/payment/PaymentToVoidOrTransfer';
import { Payment } from 'app/types/shared/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  readonly path = '/payment';

  constructor(private _http: HttpClient) {
  }

  getPaymentSummary(request: PaymentSearchRequest): Observable<Response<PaymentSummary>> {
    return this._http.post<Response<PaymentSummary>>(`${environment.apiUrl}${this.path}/summary`, request);
  }

  searchPayments(request: PaymentSearchRequest): Observable<Response<PagedSearchResults<PaymentHistory>>> {
    return this._http.post<Response<PagedSearchResults<PaymentHistory>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  voidFeeWaiver(request: VoidOrWaivePaymentBatchRequest): Observable<Response<PaymentHistory>> {
    return this._http.post<Response<PaymentHistory>>(`${environment.apiUrl}${this.path}/voidFeeWaiver`, request);
  }

  floorplanFeeWaiverCanBeVoided(floorplanId: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(`${environment.apiUrl}${this.path}/feeWaiver/floorplan/${floorplanId}`);
  }

  markPaymentAsNSF(request: VoidOrWaivePaymentBatchRequest): Observable<Response<PaymentHistory>> {
    return this._http.post<Response<PaymentHistory>>(`${environment.apiUrl}${this.path}/markPaymentAsNSF`, request);
  }

  voidPayment(request: VoidOrWaivePaymentBatchRequest): Observable<Response<PaymentHistory>> {
    return this._http.post<Response<PaymentHistory>>(`${environment.apiUrl}${this.path}/voidPayment`, request);
  }

  transferPayment(request: VoidOrWaivePaymentBatchRequest): Observable<Response<PaymentHistory>> {
    return this._http.post<Response<PaymentHistory>>(`${environment.apiUrl}${this.path}/transferPayment`, request);
  }
  floorplanPaymentsCanBeTransferred(floorplanIds: string[]): Observable<Response<boolean>> {
    return this._http.post<Response<boolean>>(`${environment.apiUrl}${this.path}/transfer/floorplans`, floorplanIds);
  }

  createMarkPaymentAsNSFRequest(request: PaymentVoidRequest): Observable<Response<VoidOrWaivePaymentBatchRequest>> {
    return this._http.post<Response<VoidOrWaivePaymentBatchRequest>>(`${environment.apiUrl}${this.path}/createMarkPaymentAsNSFRequest`, request);
  }
  createVoidPaymentRequest(request: PaymentVoidRequest): Observable<Response<VoidOrWaivePaymentBatchRequest>> {
    return this._http.post<Response<VoidOrWaivePaymentBatchRequest>>(`${environment.apiUrl}${this.path}/createVoidPaymentRequest`, request);
  }
  createTransferPaymentToUnappliedRequest(request: PaymentVoidRequest): Observable<Response<VoidOrWaivePaymentBatchRequest>> {
    return this._http.post<Response<VoidOrWaivePaymentBatchRequest>>(`${environment.apiUrl}${this.path}/createTransferPaymentToUnappliedRequest`, request);
  }
  createVoidFeeWaiverRequest(request: PaymentVoidRequest): Observable<Response<VoidOrWaivePaymentBatchRequest>> {
    return this._http.post<Response<VoidOrWaivePaymentBatchRequest>>(`${environment.apiUrl}${this.path}/createVoidFeeWaiverRequest`, request);
  }

  getMostRecentPayment(floorplanIds: string[]): Observable<Response<Date>> {
    return this._http.post<Response<Date>>(`${environment.apiUrl}${this.path}/getMostRecentPayment/`, floorplanIds);
  }

  getPaymentsMadeByCheckOrWireAsync(floorplanId: string): Observable<PaymentToVoidOrTransfer[]> {
    return this._http.get<PaymentToVoidOrTransfer[]>(`${environment.apiUrl}${this.path}/wireAndcheckPayments/${floorplanId}`);
  }

  wasPaymentNonCashAsync(paymentId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/wasPaymentNonCash/${paymentId}`)
  }

  getLastPaymentInfo(floorplanId: string): Observable<Payment> {
    return this._http.get<Payment>(`${environment.apiUrl}${this.path}/getLastPaymentInfo/${floorplanId}`);
  }

  getHistoriesWithReceipts(paymentHistories: PaymentHistory[]): Observable<Response<PaymentHistory[]>> {
    return this._http.post<Response<PaymentHistory[]>>(`${environment.apiUrl}${this.path}/getHistoriesWithReceipts`, paymentHistories);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadReceipt(receiptNumber: number): any {
    return this._http.get(
      `${environment.apiUrl}${this.path}/paymentReceipt/${receiptNumber}`,
      { responseType: 'blob', observe: 'response' }
    );
  }
}
