import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { VinLookup } from 'app/types/shared/vinLookup';
import { MisMatchCollateralResponse } from 'app/types/shared/misMatchCollateralResponse';

@Injectable({
  providedIn: 'root'
})
export class VinLookupService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/vinLookup';
  }

  getVinLookupSimpleAsync(vin: string): Observable<Response<VinLookup>> {
    return this._http.get<Response<VinLookup>>(`${environment.apiUrl}${this.path}/vin-lookup-simple/${vin}`);
  }

  getVinLookupFullAsync(vin: string, uvc: string, mileage: string): Observable<Response<VinLookup>> {
    return this._http.get<Response<VinLookup>>(`${environment.apiUrl}${this.path}/vin-lookup-full/${vin}/${uvc}/${mileage}`);
  }

  requestBlackBookMatchingErrorDialog(floorplanId: string): Observable<Response<MisMatchCollateralResponse>> {
    return this._http.get<Response<MisMatchCollateralResponse>>(`${environment.apiUrl}${this.path}/${floorplanId}/request-black-book-matching-error-dialog`);
  }
}
