import { NgModule } from '@angular/core';
import { PhoneMaskDirective, TaxIdMaskDirective, SocialSecurityMaskDirective, AddressPipe } from '@coastal';
import { CommonModule } from '@angular/common';
import { TitleBarComponent } from './layout/components/title-bar/title-bar.component';
import { MaterialModule } from 'app/app-material.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { CoastalSharedModule } from '@coastal/coastal-shared.module';
import { AddressComponent } from '@coastal/components/address/address.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { FuseSidebarModule } from '@fuse/components';
import { NgxMaskModule } from 'ngx-mask';
import { BankingComponent } from './banking/banking.component';
import { BankingPreferencesEditDialogComponent } from './banking/banking-preferences-edit-dialog/banking-preferences-edit-dialog.component';
import { BankingAccountInfoEditDialogComponent } from './banking/banking-account-info-edit-dialog/banking-account-info-edit-dialog.component';
import { TextFilterComponent } from './filters/text-filter/text-filter.component';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { MultiSelectFilterComponent } from './filters/multi-select-filter/multi-select-filter.component';

import { FedTaxIdPipe } from './pipes/fedTaxId.pipe';
import { SocialSecurityNumberPipe } from './pipes/social-security-number.pipe';
import { ChangeLogComponent } from './change-log/change-log.component';
import { TermPlanToEntityComponent } from './accounting/term-plan-to-entity/term-plan-to-entity.component';
import { AddRemoveDialogComponent } from './accounting/term-plan-to-entity/add-remove-dialog/add-remove-dialog.component';
import { UtcDatePipe } from '@coastal/pipes/utcDate.pipe';
import { UtcDateTimePipe } from '@coastal/pipes/utcDateTime.pipe';
import { InconsistentDatePipe } from '@coastal/pipes/inconsistentDate.pipe';
import { CanDeactivateGuard } from './guards/can-deactivate-guard';
import { RulesComponent } from './rules/rules.component';
import { MarketSelectionDialogComponent } from './market-selection-dialog/market-selection-dialog.component';
import { TryUtcDateTimePipe } from '@coastal/pipes/tryUtcDateTime.pipe';
import { LocalDatePipe } from '@coastal/pipes/localDate.pipe';
import { TitleReleaseAddressComponent } from './title-release-address/title-release-address.component';
import { UpdateAttentionLineModalComponent } from './title-release-address/update-attention-line-modal/update-attention-line-modal.component';
import { TitleExposureComponent } from './title-exposure/title-exposure.component';
import { TitleExposureDetailComponent } from './title-exposure/title-exposure-detail/title-exposure-detail.component';
import { TitleExposureGroupedComponent } from './title-exposure/title-exposure-grouped/title-exposure-grouped.component';
import { UnappliedFundsBaseComponent } from './unapplied-funds-base/unapplied-funds-base.component';
import { UnappliedFundsRefundEntryModalComponent } from './unapplied-funds-base/unapplied-funds-refund-entry-modal/unapplied-funds-refund-entry-modal.component';
import { FieldNotesComponent } from './field-notes/field-notes.component';
import { FloorplanNotesComponent } from './floorplan-notes/floorplan-notes.component';
import { AddFloorplanNoteDialogComponent } from './floorplan-notes/add-floorplan-note-dialog/add-floorplan-note-dialog.component';
import { NumberFilterComponent } from './filters/number-filter/number-filter.component';
import { ContactToBusinessComponent } from 'app/pages/contact-to-business/contact-to-business.component';
import { LocationComponent } from './location/location.component';
import { AddEditContactToBusinessComponent } from 'app/pages/contact-to-business/add-edit-contact-to-business/add-edit-contact-to-business.component';
import { AddEditSignerInfoComponent } from 'app/pages/contact-to-business/signer-info/add-edit-signer-info.component';
import { AddEditLocationComponent } from './location/add-edit-location/add-edit-location.component';
import { LocationExistsValidator } from './location/location-exists.validator';
import { AssetReportRequestConfirmationDialogComponent } from 'app/pages/contact-to-business/asset-report-request-confirmation-dialog/asset-report-request-confirmation-dialog.component';
import { DocumentManagementDocumentsComponent } from './document-management/docman-documents.component';
import { ViewDocumentDetailsComponent } from './document-management/view-document-details/view-document-details.component';
import { AddDocumentDialogComponent } from './document-management/add-document-dialog/add-document-dialog.component';
import { EditDocumentDialogComponent } from './document-management/edit-document-dialog/edit-document-dialog.component';
import { SignerInfoTabComponent } from 'app/pages/contact-to-business/signer-info-tab/signer-info-tab.component';
import { SsnPipe } from 'app/pages/contact-to-business/signer-info-tab/ssn.pipe';
import { TerminateSignerConfirmationDialogComponent } from 'app/pages/contact-to-business/terminate-signer-confirmation-dialog/terminate-signer-confirmation-dialog.component';

@NgModule({
  declarations: [
    PhoneMaskDirective,
    TaxIdMaskDirective,
    SocialSecurityMaskDirective,
    TitleBarComponent,
    FedTaxIdPipe,
    UtcDatePipe,
    UtcDateTimePipe,
    InconsistentDatePipe,
    ErrorDialogComponent,
    AddressComponent,
    ComingSoonComponent,
    SocialSecurityNumberPipe,
    AddressPipe,
    BankingComponent,
    BankingPreferencesEditDialogComponent,
    BankingAccountInfoEditDialogComponent,
    ChangeLogComponent,
    TermPlanToEntityComponent,
    AddRemoveDialogComponent,
    TextFilterComponent,
    NumberFilterComponent,
    DateFilterComponent,
    MultiSelectFilterComponent,
    RulesComponent,
    MarketSelectionDialogComponent,
    TryUtcDateTimePipe,
    LocalDatePipe,
    TitleReleaseAddressComponent,
    UpdateAttentionLineModalComponent,
    TitleExposureComponent,
    TitleExposureGroupedComponent,
    TitleExposureDetailComponent,
    UnappliedFundsBaseComponent,
    UnappliedFundsRefundEntryModalComponent,
    FieldNotesComponent,
    FloorplanNotesComponent,
    AddFloorplanNoteDialogComponent,
    ContactToBusinessComponent,
    AddEditLocationComponent,
    LocationComponent,
    AddEditContactToBusinessComponent,
    AddEditSignerInfoComponent,
    AssetReportRequestConfirmationDialogComponent,
    DocumentManagementDocumentsComponent,
    ViewDocumentDetailsComponent,
    AddDocumentDialogComponent,
    EditDocumentDialogComponent,
    SignerInfoTabComponent,
    SsnPipe,
    TerminateSignerConfirmationDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FuseSharedModule,
    FuseSidebarModule,
    CoastalSharedModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule
  ],
  exports: [
    PhoneMaskDirective,
    TaxIdMaskDirective,
    SocialSecurityMaskDirective,
    TitleBarComponent,
    ComingSoonComponent,
    FedTaxIdPipe,
    NgxCurrencyModule,
    FuseSharedModule,
    FuseSidebarModule,
    CommonModule,
    CoastalSharedModule,
    MaterialModule,
    AddressComponent,
    SocialSecurityNumberPipe,
    AddressPipe,
    UtcDatePipe,
    UtcDateTimePipe,
    InconsistentDatePipe,
    NgxMaskModule,
    BankingComponent,
    TextFilterComponent,
    NumberFilterComponent,
    DateFilterComponent,
    MultiSelectFilterComponent,
    RulesComponent,
    TryUtcDateTimePipe,
    LocalDatePipe,
    TitleReleaseAddressComponent,
    UpdateAttentionLineModalComponent,
    TitleExposureComponent,
    TitleExposureGroupedComponent,
    TitleExposureDetailComponent,
    DocumentManagementDocumentsComponent,
    ViewDocumentDetailsComponent
  ],

  providers: [CanDeactivateGuard, LocationExistsValidator]
})
export class SharedModule { }
