
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { LoanGroup } from 'app/types/accounting/loan-group/loanGroup';
import { LoanGroupToGeneralLedgerCriteria } from 'app/types/accounting/loan-group/loanGroupToGeneralLedgerCriteria';
import { LoanGroupToGeneralLedgerAccountToGeneralLedgerAccountPlaceholder } from 'app/types/accounting/loan-group/LoanGroupToGeneralLedgerAccountToGeneralLedgerAccountPlaceholder';

@Injectable({
  providedIn: 'root'
})
export class LoanGroupService extends BreadcrumbService {
  private readonly path = '/loanGroup';

  constructor(private _http: HttpClient) {
    super();
  }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<LoanGroup>>> {
    return this._http.post<Response<PagedSearchResults<LoanGroup>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  addLoanGroup(loanGroup: LoanGroup): Observable<Response<LoanGroup>> {
    return this._http.post<Response<LoanGroup>>(`${environment.apiUrl}${this.path}/add`, loanGroup);
  }

  getLoanGroup(loanGroupId: string): Observable<Response<LoanGroup>> {
    return this._http.get<Response<LoanGroup>>(`${environment.apiUrl}${this.path}/${loanGroupId}`);
  }

  updateLoanGroup(loanGroup: LoanGroup): Observable<Response<LoanGroup>> {
    return this._http.put<Response<LoanGroup>>(`${environment.apiUrl}${this.path}/update`, loanGroup);
  }

  updateLoanGroupToGenerlLedgerAccountPlaceHolderMappings(mappings: LoanGroupToGeneralLedgerAccountToGeneralLedgerAccountPlaceholder[]): Observable<Response<LoanGroup>> {
    return this._http.put<Response<LoanGroup>>(`${environment.apiUrl}${this.path}/update/mappings`, mappings);
  }

  getLoanGroupToGeneralLedgerCriteria(): Observable<Response<LoanGroupToGeneralLedgerCriteria>> {
    return this._http.get<Response<LoanGroupToGeneralLedgerCriteria>>(`${environment.apiUrl}${this.path}/mappings/criteria`);
  }
}
