import { Injectable } from '@angular/core';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { User } from 'app/types/security/user-model/user';
import { UpdateSecurityGroupsForUser } from 'app/types/security/user-model/UpdateSecurityGroupsForUser';
import { UpdatePermissionsForUser } from 'app/types/security/user-model/UpdatePermissionsForUser';
import { UpdateRolesForUser } from 'app/types/security/user-model/UpdateRolesForUser';

@Injectable({
  providedIn: 'root',
})
export class UserManagementSelectionUsersService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/user';
  }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<User>>> {
    return this._http.post<Response<PagedSearchResults<User>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  getUser(userId: string): Observable<Response<User>> {
    return this._http.get<Response<User>>(`${environment.apiUrl}${this.path}/detail/` + userId);
  }

  saveSecurityGroupsForUser(updateSecurityGroupsForUser: UpdateSecurityGroupsForUser): Observable<Response<User>> {
    return this._http.post<Response<User>>(`${environment.apiUrl}${this.path}/updateSecurityGroups/`, updateSecurityGroupsForUser);
  }

  savePermissionsForUser(updatePermissionsForUser: UpdatePermissionsForUser): Observable<Response<User>> {
    return this._http.post<Response<User>>(`${environment.apiUrl}${this.path}/updatePermissions/`, updatePermissionsForUser);
  }

  saveRolesForUser(updateRolesForUser: UpdateRolesForUser): Observable<Response<User>> {
    return this._http.post<Response<User>>(`${environment.apiUrl}${this.path}/updateRoles/`, updateRolesForUser);
  }

  getAllUsers(): Observable<Response<User[]>> {
    return this._http.get<Response<User[]>>(`${environment.apiUrl}${this.path}/getall/`);
  }
}
