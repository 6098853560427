import {FuseNavigation} from '@fuse/types';
import {SecurityService} from './services/application/security/security.service';

export const getNavigation = (securityService: SecurityService): FuseNavigation[] => {
  return [
    {
      id: 'welcome',
      title: 'Welcome',
      type: 'item',
      url: '/welcome',
      icon: 'dashboard'
    },
    {
      id: 'myqueue',
      title: 'My Queue',
      type: 'item',
      url: '/myqueue',
      icon: 'contact_phone',
      hidden: !securityService.hasRole('Administrator')
    },
    {
      id: 'accounting',
      title: 'Accounting',
      type: 'collapsable',
      icon: 'monetization_on',
      hidden: !securityService.hasRole('Accounting') && !securityService.hasRole('AccountingManager'),
      children: [
        {
          id: 'page-general-ledger-account-types',
          title: 'Account Types',
          hidden: !securityService.hasRole('AccountingManager'),
          type: 'item',
          url: '/accounting/general-ledger-account-types'
        },
        {
          id: 'disbursements-search',
          title: 'Disbursements',
          type: 'item',
          url: '/accounting/disbursements/search',
          hidden: !securityService.hasRole('Accounting')
        },
        {
          id: 'page-general-ledger-accounts',
          title: 'General Ledger Accounts',
          hidden: !securityService.hasRole('AccountingManager'),
          type: 'item',
          url: '/accounting/general-ledger-accounts'
        },
        {
          id: 'loan-groups',
          title: 'Loan Groups',
          hidden: !securityService.hasRole('AccountingManager'),
          type: 'item',
          url: '/accounting/loan-groups'
        },
        {
          id: 'page-placeholder-accounts',
          title: 'Placeholder Accounts',
          hidden: !securityService.hasRole('AccountingManager'),
          type: 'item',
          url: '/accounting/placeholder-accounts'
        },
        {
          id: 'payment-methods',
          title: 'Payment Methods',
          hidden: !securityService.hasRole('AccountingManager'),
          type: 'item',
          url: '/accounting/payment-methods'
        },
        {
          id: 'process-disbursements',
          title: 'Process Disbursements',
          type: 'item',
          url: '/accounting/disbursements/process',
          hidden: !securityService.hasRole('Accounting')
        },
        {
          id: 'page-general-ledger-receivable-types',
          title: 'Receivable/Payment Types',
          type: 'item',
          hidden: !securityService.hasRole('AccountingManager'),
          url: '/accounting/general-ledger-receivable-types'
        }
      ]
    },
    {
      id: 'dealers',
      title: 'Dealers',
      type: 'item',
      url: '/dealers',
      icon: 'directions_car'
    },
    {
      id: 'auctions',
      title: 'Auctions/Others',
      type: 'item',
      url: '/auctions',
      icon: 'gavel'
    },
    {
      id: 'contact',
      title: 'Contacts',
      type: 'item',
      url: '/contacts',
      icon: 'person_outline'
    },
    {
      id: 'floorplan',
      title: 'Floorplan',
      type: 'item',
      url: '/floorplan',
      icon: 'widgets'
    },
    {
      id: 'titles-queue',
      title: 'Titles Queue',
      type: 'item',
      url: '/titles-queue',
      icon: 'find_in_page'
    },
    {
      id: 'title-release-business-addresses',
      title: 'Title Release Business Addresses',
      type: 'item',
      url: '/title-release-business-addresses',
      icon: 'not_listed_location'
    },
    {
      id: 'administration',
      title: 'Admin',
      type: 'collapsable',
      icon: 'settings',
      children: [
        {
          id: 'page-administration-user-management',
          title: 'User Management',
          type: 'item',
          url: '/administration/usermanagement',
          hidden: !securityService.hasRole('Administrator')
        },
        {
          id: 'termplan',
          title: 'Term Plans',
          type: 'item',
          url: '/administration/termplans'
        },
        {
          id: 'auctionFloatSale',
          title: 'Auction Float Sale',
          type: 'item',
          url: '/administration/auctionfloatsale'
        },
        {
          id: 'securedParties',
          title: 'Secured Parties',
          type: 'item',
          url: '/administration/securedparties'
        },
        {
          id: 'insurancePrograms',
          title: 'Insurance Programs',
          type: 'item',
          url: '/administration/insurancePrograms'
        },
        {
          id: 'regionMarkets',
          title: 'Markets - Regions',
          type: 'item',
          url: '/administration/regionmarkets'
        },
        {
          id: 'ratesBase',
          title: 'Rates -> Base',
          type: 'item',
          url: '/administration/rates-base',
          hidden: !securityService.hasRole('Administrator')
        },
        {
          id: 'banners',
          title: 'K360 Banner',
          type: 'item',
          url: '/administration/banners',
          hidden: !securityService.hasRole('Administrator')
        },
        {
          id: 'document-type',
          title: 'Document Setup',
          type: 'item',
          url: '/administration/document-type',
          hidden: !securityService.isEnabledDocumentSetup || !securityService.hasRole('Administrator')
        }
      ]
    }
  ];
};
