import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoggingService } from '@coastal';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { environment } from '@environment';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  readonly chunkFailedMessage = /ChunkLoadError: Loading chunk/;
  readonly HttpCode405Message = /status":405/;
  readonly unknownFailedMessage = /Unknown Error/;

    constructor(private loggingService: LoggingService,
      public _matDialog: MatDialog) {
        super();
    }

    handleError(error: Error) {
      if(this.chunkFailedMessage.test(error.message) || (this.HttpCode405Message.test(error.message) && environment.buildNumber !== 'local')) {
        this.confirmDialogError();
      }
      else if(this.unknownFailedMessage.test(error.message)){
        this.loggingService.warning(error);
      }
      else{
        this.loggingService.error(error);
      }
    }

    confirmDialogError(): void
    {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: true,
            panelClass: 'confirm-custom-modalbox'
        });
        this.confirmDialogRef.componentInstance.confirmMessage = 'New version available. Load New Version?';
        this.confirmDialogRef.afterClosed().subscribe(result => {
            if ( result )
            {
              window.location.reload();
            }
            this.confirmDialogRef = null;
        });
    }
}
