import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Dealer } from 'app/types/dealer/dealer';
import { FindSpecificSourceDealerRequest } from 'app/types/dealer/findSpecificSourceDealerRequest';
import { FloorplanPartyForSelection } from 'app/pages/floorplan/floorplanPartyForSelection';
import { DealerToSpecificSource } from 'app/types/dealer/dealerToSpecificSource';

@Injectable({
  providedIn: 'root'
})
export class SpecificSourceService {
  readonly _path = '/specificsource';

  constructor(private _http: HttpClient) {}

  get(dealerId: string): Observable<Response<DealerToSpecificSource[]>> {
    return this._http.get<Response<DealerToSpecificSource[]>>(`${environment.apiUrl}${this._path}/${dealerId}`);
  }

  find(findSpecificSourceDealerRequest: FindSpecificSourceDealerRequest): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this._path}/find`, findSpecificSourceDealerRequest);
  }

  save(dealerToSpecificSource: DealerToSpecificSource): Observable<Response<DealerToSpecificSource[]>> {
    return this._http.post<Response<DealerToSpecificSource[]>>(`${environment.apiUrl}${this._path}`, dealerToSpecificSource);
  }

  delete(dealerToSpecificSourceId: string): Observable<Response<DealerToSpecificSource[]>> {
    return this._http.delete<Response<DealerToSpecificSource[]>>(`${environment.apiUrl}${this._path}/${dealerToSpecificSourceId}`);
  }

  getSpecificSourceDealerSellersForFloorplanSelection(dealerId: string) {
    return this._http.get<Response<FloorplanPartyForSelection[]>>(
      `${environment.apiUrl}${this._path}/getSpecificSourceDealerSellersForFloorplanSelection/${dealerId}`
    );
  }
}
