import {Injectable} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import moment, {Moment} from 'moment';
import {FormControl} from '@angular/forms';
import * as momenttz from 'moment-timezone';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  static PhoneNumberRegex = new RegExp(/(?:\D*\d){10}/);
  static CannotStartWithWhiteSpaceRegex = new RegExp(/^[^\s]+.*$/);
  static EmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  static SSNRegex = new RegExp(/^\d{3}-\d{2}-\d{4}$/);
  static PostalCodeRegex = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);

  static clone = cloneDeep;

  static compare = (a: string | number | Date, b: string | number | Date, isAsc = false): number => (a < b ? -1 : 1) * (isAsc ? 1 : -1);

  static caseInsensitiveStringCompare(a: string, b: string, isAsc: boolean) {
    const x = a ? a.toUpperCase() : '';
    const y = b ? b.toUpperCase() : '';
    return (x < y ? -1 : 1) * (isAsc ? 1 : -1);
  }

  static MatSortingDataAccessor(item, property) {
    const titleLocation = 'titleLocation';
    if (property === titleLocation) {
      return item.titleLocation.name;
    }
    const parts = property.split('.');
    switch (parts.length) {
      case 2:
        return item.hasOwnProperty(parts[0]) && item[parts[0]] != null ? item[parts[0]][parts[1]] : undefined;
      case 3:
        return item.hasOwnProperty(parts[0]) && item[parts[0]] != null && item[parts[0]].hasOwnProperty([parts[1]]) && item[parts[0]][parts[1]] != null
          ? item[parts[0]][parts[1]][parts[2]]
          : undefined;
      default:
        return item[property];
    }
  }

  static getRandomInteger(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max) + 1;
    return Math.floor(Math.random() * (max - min)) + min;
  }

  static groupBy(list, key) {
    const map = {};
    list.forEach(item => {
      const value = item[key];
      const collection = map[value];
      if (!collection) {
        map[value] = [item];
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static groupBySecondLevelKey(list, key1, key2) {
    const map = {};
    list.forEach(item => {
      const value = item[key1][key2];
      const collection = map[value];
      if (!collection) {
        map[value] = [item];
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static updateFilter(data, filteredValues) {
    filteredValues[data.fieldName] = data?.value === undefined ? '' : data?.value;
  }

  static updateMultiFilter(data, multiFilteredValues) {
    if (data.values == null) {
      multiFilteredValues[data.fieldName] = [];
    } else {
      const names = data?.values.map(function(item) {
        return item['name'];
      });
      multiFilteredValues[data.fieldName] = names;
    }
  }

  static localToUtc(local: string): string {
    const utcMoment = moment(local).utc();
    return utcMoment.format('YYYY-MM-DD HH:mm:ss.SSS');
  }

  static toLocalDateUnlessThereIsNoTimeComponent(date: Date): string {
    if (date) {
      if (moment(date).format('HH:mm:ss.SSS') === '00:00:00.000') {
        return moment.utc(date).format('M/D/YYYY');
      } else {
        return moment
          .utc(date)
          .local()
          .format('M/D/YYYY');
      }
    }
    return '';
  }

  static UtctoEasternTime(inputDate: Date, resultformat:string): string {
    if(inputDate){
      const start = momenttz.tz(inputDate, 'UTC') // original timezone
      return start.tz('America/New_York').format(resultformat)
    }
    return '';
  }


  static whiteSpacesInvalid(control: FormControl) {
    const isWhiteSpace = (control.value || '').trim().length === 0;
    const isValid = !isWhiteSpace;
    return isValid ? null : {whiteSpaceInvalid: true};
  }
}
