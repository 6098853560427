import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { SignalRService } from 'app/services/application/signalR/signal-r.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private oAuthService: OAuthService,
        private _signalRService: SignalRService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      this.manageSignalRWebpageUser();

         if (this.oAuthService.hasValidAccessToken()) {
           return true;
         } else {
          this.router.navigate(['/welcome'], { queryParams: { returnUrl: state.url } });
          return false;
         }
    }

    private manageSignalRWebpageUser(): void {
      if (this._signalRService.webpageUser) {
         this._signalRService.removeUserFromGroup(this._signalRService.webpageUser);
      }
    }
}
