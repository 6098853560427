import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  readonly _path = '/export';

  constructor(private _http: HttpClient) {}

  get(floorplanId: string): Observable<Response<string>> {
    return this._http.get<Response<string>>(`${environment.apiUrl}${this._path}/${floorplanId}`);
  }
}
