import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@environment';
import {SubLedger} from 'app/types/accounting/bookkeeping/subLedger/subLedger';
import {Observable} from 'rxjs';
import {Response} from '@coastal/types/response';
import { SubLedgerItem } from 'app/types/accounting/bookkeeping/subLedger/subLedgerItem';

@Injectable({
  providedIn: 'root'
})
export class SubLedgerService {
  private readonly path = '/subledger';
  constructor(private _http: HttpClient) {}
  get(key: string): Observable<Response<SubLedger>> {
    return this._http.get<Response<SubLedger>>(`${environment.apiUrl}${this.path}/?key=${key}`);
  }
  addSubLedgerItemsAsync(items: SubLedgerItem[], key: string): Observable<Response<SubLedger>> {
    return this._http.post<Response<SubLedger>>(`${environment.apiUrl}${this.path}/?key=${key}`, items);
  }
}
