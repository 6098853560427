import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Observable } from 'rxjs';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { SecuredPartySearchResult } from 'app/pages/administration/secured-party/secured-party-search/securedPartySearchResult';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { SupportsFiltering } from '@coastal/types/supportsFiltering';
import { SecuredParty } from 'app/types/accounting/secured-party/securedParty';

@Injectable({
  providedIn: 'root'
})
export class SecuredPartyService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/securedparty';
  }

  searchAsync(
    request: PagedSearchRequest | SupportsFiltering
  ): Observable<Response<PagedSearchResults<SecuredPartySearchResult>>> {
    return this._http.post<
      Response<PagedSearchResults<SecuredPartySearchResult>>
    >(`${environment.apiUrl}${this.path}/search`, request);
  }

  addSecuredPartyAsync(
    securedParty: SecuredParty
  ): Observable<Response<SecuredParty>> {
    return this._http.post<Response<SecuredParty>>(
      `${environment.apiUrl}${this.path}/add`,
      securedParty
    );
  }

  editSecuredPartyAsync(
    securedParty: SecuredParty
  ): Observable<Response<SecuredParty>> {
    return this._http.post<Response<SecuredParty>>(
      `${environment.apiUrl}${this.path}/edit`,
      securedParty
    );
  }

  getSecuredParty(id: string): Observable<Response<SecuredParty>> {
    return this._http.get<Response<SecuredParty>>(
      `${environment.apiUrl}${this.path}/${id}`
    );
  }
}
