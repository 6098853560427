import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './app-material.module';

import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { LayoutModule } from './components/layout/layout.module';

import { AppRoutingModule } from './app-routing.module';

import { BusyInterceptor } from './services/busy.interceptor';
import { BusyOverlayModule } from 'app/components/layout/components/busy-overlay/busy-overlay.module';

import { environment } from '@environment';
import { DefaultOAuthInterceptor, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { SharedModule } from './components/shared.module';
import { HttpErrorInterceptor } from 'app/services/http-error.interceptor';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorHandlerService } from './services/api/errorHandler/ErrorHandlerService';
import { OAuthInterceptor } from './services/oAuthInterceptor';

export const miscProviders =
  [
    { provide: OAuthStorage, useValue: localStorage },
    { provide: HTTP_INTERCEPTORS, useClass: OAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BusyInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ];

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [miscProviders],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    SharedModule,

    // Material modules
    MaterialModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    AppRoutingModule,
    BusyOverlayModule,

    // OAuth
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl, environment.authenticationApiUrl, 'https://graph.microsoft.com/v1.0/me', environment.assetReportApiUrl],
        sendAccessToken: true
      }
    }),
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
