import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Response} from '@coastal/types/response';
import {PagedSearchResults} from '@coastal/types/pagedSearchResults';
import {environment} from '@environment';
import {ProcessDisbursementsRequest} from 'app/types/accounting/voucher/processDisbursementsRequest';
import {VoucherSearch} from 'app/types/accounting/voucher/voucherSearch';
import {VoucherSearchRequest} from 'app/types/accounting/voucher/voucherSearchRequest';
import {FloorplanDisbursementResponse} from 'app/types/floorplan/floorplanDisbursementResponse';
import {DisbursementMethodUpdateRequest} from 'app/pages/accounting/disbursements/process/disbursementMethodUpdateRequest';
import {AddHoldDisbursementRequest} from 'app/types/accounting/voucher/addHoldDisbursementRequest';
import {RemoveHoldDisbursementRequest} from 'app/types/accounting/voucher/removeHoldDisbursementRequest';
import {DisbursementHold} from 'app/types/accounting/voucher/disbursementHold';
import {VoidDisbursementRequest} from 'app/types/accounting/voucher/voidDisbursementRequest';

@Injectable({
  providedIn: 'root'
})
export class DisbursementService {
  private readonly _path = '/disbursement';

  constructor(private _http: HttpClient) {}

  search(request: VoucherSearchRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/search`, request);
  }

  processChecks(request: ProcessDisbursementsRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/process-checks`, request);
  }

  processUnappliedFunds(request: ProcessDisbursementsRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/process-unapplied-funds`, request);
  }

  processWire(request: ProcessDisbursementsRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/process-wire`, request);
  }

  processNonCash(request: ProcessDisbursementsRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/process-non-cash`, request);
  }

  getFloorplanDisbursementsAsync(floorplanId: string): Observable<Response<FloorplanDisbursementResponse[]>> {
    return this._http.get<Response<FloorplanDisbursementResponse[]>>(`${environment.apiUrl}${this._path}/getFloorplanDisbursements/${floorplanId}`);
  }

  updateVoucherSummaryDisbursementMethodAsync(request: DisbursementMethodUpdateRequest): Observable<Response<any>> {
    return this._http.post<Response<any>>(`${environment.apiUrl}${this._path}/update-disbursement-method`, request);
  }

  addDisbursementHold(request: AddHoldDisbursementRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/add-disbursement-hold`, request);
  }

  removeHoldDisbursement(request: RemoveHoldDisbursementRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/remove-disbursement-hold`, request);
  }

  getDisbursementHoldDetails(voucherSummaryId: string): Observable<Response<DisbursementHold>> {
    return this._http.get<Response<DisbursementHold>>(`${environment.apiUrl}${this._path}/get-disbursement-hold-details/${voucherSummaryId}`);
  }

  voidDisbursementAsync(request: VoidDisbursementRequest): Observable<Response<PagedSearchResults<VoucherSearch>>> {
    return this._http.post<Response<PagedSearchResults<VoucherSearch>>>(`${environment.apiUrl}${this._path}/void-disbursement`, request);
  }
}
