import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@environment';
import * as signalR from '@microsoft/signalr';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { WebpageUser } from 'app/types/signalR/webpageUser';
import moment from 'moment';
import { Observable } from 'rxjs';
/*import { AzureKeyCredential, EventGridPublisherClient } from '@azure/eventgrid';*/
import { Guid } from 'guid-typescript'

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public hubConnection: signalR.HubConnection;
  public webpageUser: WebpageUser;
  public showWebpageUsers = false;

  @Output() webpageUsers: EventEmitter<unknown> = new EventEmitter();

  constructor(
    private _http: HttpClient,
    private authStorage: OAuthStorage) {}

  public startConnection(): Promise<unknown>{
    return new Promise((resolve, reject) => {
      const token = this.authStorage.getItem('access_token');
      const options: signalR.IHttpConnectionOptions = {
        accessTokenFactory: () => {
          return token as string;
        },
        transport: signalR.HttpTransportType.WebSockets
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.webpageTrackingApiUrl, options)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      this.hubConnection.start()
        .then(() => {
          console.log('connection established');

          this.registerSignalREvents();

          return resolve(true);
        })
        .catch((err: unknown): void => {
          console.log('error occured' + err);
          reject(err);
        });

        /*read about this*/
       /* this.hubConnection.onclose((error) => {
          this.hubConnection.start();
          console.error(`Something went wrong: ${error}`);
        });*/

        /*this vs withAutomaticReconnect
        /*this.hubConnection.serverTimeoutInMilliseconds = 300000;
      this.hubConnection.keepAliveIntervalInMilliseconds = 300000;*/

    });
  }

  public addUserToGroup(webpageUser: WebpageUser): Promise<unknown>{
    let returnVal: Observable<unknown>;
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      console.log('AddUserToGroup - state was disconnected!');

      this.startConnection().then(() =>
        returnVal = this.addUserToGroupEndpoint(webpageUser)
      )
    }
    else {
      console.log('AddUserToGroup');
      returnVal = this.addUserToGroupEndpoint(webpageUser);
    }

      return returnVal.toPromise();
  }

  public removeUserFromGroup(webpageUser: WebpageUser): void {
    /*let returnVal: Observable<unknown>;*/
    if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
      console.log('RemoveUserFromGroup - state was disconnected!');
      this.startConnection().then(() =>
        /*returnVal = */ this.removeUserFromGroupEndpoint(webpageUser)
      )
    }
    else {
      console.log('RemoveUserFromGroup');
      /*returnVal = */ this.removeUserFromGroupEndpoint(webpageUser);
    }

      /*return returnVal.toPromise();*/
  }

  public buildWebpageUser(connectionId: string, group: string, userId: string): WebpageUser {
    return {
      signalRConnectionId: connectionId,
      signalRGroup: group,
      userId: userId,
    };
  }

  private registerSignalREvents(){
    this.hubConnection.on('publishWebpageUsers', (data: string) => {
      let users = data.split(',');
      users = users.filter(x => x !== this.webpageUser.userId);
      if(users.length > 0) {
      this.webpageUsers.emit(users);
      this.showWebpageUsers = true;
    }
    else{
      this.showWebpageUsers = false;
    }
    });
  }

  private addUserToGroupEndpoint(
    webpageUser: WebpageUser
  ){
    this.webpageUser = webpageUser;
        return this._http.post(
      `${environment.webpageTrackingApiUrl}/addusertogroup?code=OAHeYYWr1GoB72USAE18leiq5hJEixYIQnAjjQAFUbkUAzFuoJVbjg==`, webpageUser
    );
  }

  /*private removeUserFromGroupEndpoint(
    webpageUser: WebpageUser
  ){
    this.webpageUser = undefined;
    this.webpageUsers = undefined;
    this.showWebpageUsers = false;

    const client = new EventGridPublisherClient(
      'https://darkhorse-endava-customevents.northcentralus-1.eventgrid.azure.net/api/events',
      'EventGrid',
      new AzureKeyCredential('Ga2kfa92ZtPR7vUGLLpgM/rfzGa8/l5BKmcVeLAwHfM=')); /*event grid api key

      client.send([
        {
          topic: 'KATestTopic',
          subject: 'RemoveWebpageTrackingUser',
          eventType: 'All',
          data: webpageUser,
          dataVersion: '1'
        }
      ]);
  }*/

  private removeUserFromGroupEndpoint(
    user: WebpageUser
  ){
    this.webpageUser = undefined;
    this.webpageUsers = undefined;
    this.showWebpageUsers = false;

    const eventGridSchema = {
          topic: 'KATestTopic',
          subject: 'RemoveWebpageTrackingUser',
          eventType: 'All',
          id: Guid.create(),
          eventTime: moment.utc().format(),
          data: user,
          dataVersion: '1'
    };

    const httpHeaders = new HttpHeaders();
    httpHeaders.set('aeg-sas-key', 'Ga2kfa92ZtPR7vUGLLpgM/rfzGa8/l5BKmcVeLAwHfM=')

    return this._http.post(
      'https://darkhorse-endava-customevents.northcentralus-1.eventgrid.azure.net/api/events', eventGridSchema,
      { headers : httpHeaders}
    ).subscribe((response) => console.log(response) );
  }
}
