
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { NewAuctionDetails } from 'app/components/accounting/newAuctionDetails';
import { Action } from 'app/types/action';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { AuctionSlim } from 'app/types/accounting/auction/auctionSlim';
import { SupportsFiltering } from '@coastal/types/supportsFiltering';
import { FloorplanPartyForSelection } from 'app/pages/floorplan/floorplanPartyForSelection';
import { Auction } from 'app/types/accounting/auction/auction';
import { AuctionSearchRow } from 'app/types/accounting/auction/auctionSearchRow';
import { AuctionDuplicateSearchResult } from 'app/types/accounting/auction/auctionDuplicateSearchResult';
import { UnappliedFundsRefundRequest } from 'app/types/dealer/unappliedFundsRefundRequest';
import { TerminationReason } from 'app/types/shared/terminationReason';
import { Location } from 'app/types/shared/location';
import { BusinessRecordExists } from 'app/types/shared/businessRecordExists';
import { AccountingUnappliedFundsRefundRequest } from 'app/types/dealer/accountingUnappliedFundsRefundRequest';

@Injectable({
  providedIn: 'root'
})
export class AuctionService extends BreadcrumbService {

  private readonly path = '/auction';
  private auctionInfoReadOnly = new Subject<boolean>();
  private actions = new BehaviorSubject<Action[]>([]);

  constructor(private _http: HttpClient) {
    super();
  }

  getAll(): Observable<Response<Auction[]>> {
    return this._http.get<Response<Auction[]>>(`${environment.apiUrl}${this.path}`);
  }

  getAllActiveSlim(): Observable<Response<AuctionSlim[]>> {
    return this._http.get<Response<AuctionSlim[]>>(`${environment.apiUrl}${this.path}/all/active`);
  }

  search(request: PagedSearchRequest | SupportsFiltering): Observable<Response<PagedSearchResults<AuctionSearchRow>>> {
    return this._http.post<Response<PagedSearchResults<AuctionSearchRow>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  addAuction(details: NewAuctionDetails): Observable<Response<string>> {
    return this._http.post<Response<string>>(`${environment.apiUrl}${this.path}/add`, details);
  }

  checkForDuplicates(details: NewAuctionDetails): Observable<Response<AuctionDuplicateSearchResult>> {
    return this._http.post<Response<AuctionDuplicateSearchResult>>(`${environment.apiUrl}${this.path}/duplicates`, details);
  }

  getAuction(id: string): Observable<Response<Auction>> {
    return this._http.get<Response<Auction>>(`${environment.apiUrl}${this.path}/${id}`);
  }

  updateAuction(auction: Auction): Observable<Response<Auction>> {
    return this._http.put<Response<Auction>>(`${environment.apiUrl}${this.path}/update`, auction);
  }

  updateAuctionProfile(auction: Auction): Observable<Response<Auction>> {
    return this._http.put<Response<Auction>>(`${environment.apiUrl}${this.path}/update/profile`, auction);
  }

  updateAuctionInfoReadOnly(readonly: boolean) {
    this.auctionInfoReadOnly.next(readonly);
  }

  clearAuctionInfoReadOnly() {
    this.auctionInfoReadOnly.next();
  }

  getAuctionInfoReadOnly(): Observable<any> {
    return this.auctionInfoReadOnly.asObservable();
  }

  auctionWithTaxIdExists(taxId: string, auctionId: number): Observable<Response<BusinessRecordExists>> {
    let params = new HttpParams();
    params = params.append('taxId', taxId);
    params = params.append('auctionId', auctionId.toString());
    return this._http.get<Response<BusinessRecordExists>>(`${environment.apiUrl}${this.path}/taxId/exists`, { params: params });
  }

  updateActions(actions: Action[]) {
    this.actions.next(actions);
  }

  clearActions() {
    this.actions.next([]);
  }

  getActions(): Observable<Action[]> {
    return this.actions.asObservable();
  }

  addLocationToAuction(location: Location, auctionId: string) {
    return this._http.post<Response<Auction>>(`${environment.apiUrl}${this.path}/location/${auctionId}`, location);
  }

  updateAuctionLocation(location: Location, auctionId: string) {
    return this._http.put<Response<Auction>>(`${environment.apiUrl}${this.path}/location/${auctionId}`, location);
  }

  getAuctionsForFloorplanPartySelection() {
    return this._http.get<Response<FloorplanPartyForSelection[]>>(`${environment.apiUrl}${this.path}/getAuctionsForFloorplanSelection`);
  }

  requestRefund(request: UnappliedFundsRefundRequest): Observable<Response<Auction>> {
    return this._http.post<Response<Auction>>(`${environment.apiUrl}${this.path}/refund`, request);
  }

  accountingRequestRefund(request: AccountingUnappliedFundsRefundRequest) : Observable<Response<Auction>> {
    return this._http.post<Response<Auction>>(`${environment.apiUrl}${this.path}/accounting-refund`, request);
  }

  getAuctionTerminationReasons(): Observable<Response<TerminationReason[]>>  {
    return this._http.get<Response<TerminationReason[]>>(`${environment.apiUrl}${this.path}/GetAuctionTerminationReasons`);
  }

   getAuctionLocationsById(auctionId: string): Observable<Response<Location[]>> {
    return this._http.get<Response<Location[]>>(`${environment.apiUrl}${this.path}/GetAuctionLocationsById/${auctionId}`);
  }
}
