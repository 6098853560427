import { Injectable } from '@angular/core';
import { LoggingService, UtilityService } from '@coastal';
import { BehaviorSubject } from 'rxjs';
import { Action } from 'app/types/action';

@Injectable({
  providedIn: 'root'
})
export class ActionsMenuService {
  private _actionsSubject = new BehaviorSubject<Action[]>([]);
  actions = this._actionsSubject.asObservable();

  constructor(
    private _loggingService: LoggingService,
  ) { }

  setActions(actions: Action[]) {
    this._loggingService.debug('Updating actions.');
    this._actionsSubject.next(actions.sort((a, b) => UtilityService.compare(a.label, b.label, true)));
  }
}
