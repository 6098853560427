<div id="actionsMenuContainer" class="ml-24" fxFlex>
  <button mat-raised-button [matMenuTriggerFor]="actionsMenu" *ngIf="actions?.length">Actions</button>
  <button mat-button [matMenuTriggerFor]="actionsMenu" *ngIf="!actions?.length">Actions</button>
  <mat-menu #actionsMenu="matMenu">
    <button mat-menu-item *ngFor="let action of actions" (click)="action.action()" [disabled]='action.disabled'>{{action.label}}</button>
    <div mat-menu-item *ngIf="!actions?.length">
      No actions are currently available.
    </div>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="activitiesMenu"  class="hidden-element">Activities</button>
  <mat-menu #activitiesMenu="matMenu">
    <button mat-menu-item (click)="addActivity()">Add an Activity...</button>
  </mat-menu>

  <button mat-button [matMenuTriggerFor]="tasksMenu"  class="hidden-element">Tasks</button>
  <mat-menu #tasksMenu="matMenu">
    <button mat-menu-item (click)="addTask()">Add a Task...</button>
  </mat-menu>
</div>
