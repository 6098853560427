import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/featureFlag';
  }

  isFeatureFlagEnabledAsync(featureFlagId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/isFeatureFlagEnabledAsync/${featureFlagId}`);
  }

  isFeatureManagerSnapshotEnabled(featureFlagId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/is-feature-manager-snapshot-enabled/${featureFlagId}`);
  }
}
