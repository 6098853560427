import { Injectable } from '@angular/core';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Permission } from 'app/types/security/user-model/permission';
import { UpdateSecurityGroupsForPermission } from 'app/types/security/user-model/updateSecurityGroupsForPermission';
import { UpdateRolesForPermission } from 'app/types/security/user-model/updateRolesForPermission';
import { UpdateUsersForPermission } from 'app/types/security/user-model/updateUsersForPermission';

@Injectable({
  providedIn: 'root',
})
export class UserManagementSelectionPermissionsService {
  readonly path = '/permission';

  constructor(private _http: HttpClient) { }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<Permission>>> {
    return this._http.post<Response<PagedSearchResults<Permission>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  getPermission(securityGroupId: string): Observable<Response<Permission>> {
    return this._http.get<Response<Permission>>(`${environment.apiUrl}${this.path}/detail/` + securityGroupId);
  }

  getAllPermissions(): Observable<Response<Permission[]>> {
    return this._http.get<Response<Permission[]>>(`${environment.apiUrl}${this.path}/getall/`);
  }

  saveSecurityGroupsForPermission(updateSecurityGroupsForPermission: UpdateSecurityGroupsForPermission) {
    return this._http.post<Response<Permission>>(`${environment.apiUrl}/permission/updateSecurityGroups/`, updateSecurityGroupsForPermission);

  }
  saveRolesForPermission(updateRolesForPermission: UpdateRolesForPermission) {
    return this._http.post<Response<Permission>>(`${environment.apiUrl}/permission/updateRoles/`, updateRolesForPermission);

  }
  saveUsersForPermission(updateUsersForPermission: UpdateUsersForPermission) {
    return this._http.post<Response<Permission>>(`${environment.apiUrl}/permission/updateUsers/`, updateUsersForPermission);
  }


}
