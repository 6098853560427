import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api/api.service';
import { LoggingService } from '@coastal';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  maxPeriods: number;

  constructor(
    private _apiService: ApiService,
    private _loggingService: LoggingService
  ) { }

  async initialize(): Promise<unknown> {
    this._loggingService.debug('Loading settings...');
    const response = await this._apiService.settings.getAll().toPromise();

    this.maxPeriods = parseInt(response.data['MaxPeriods'], 10);

    return response;
  }
}
