<div class="dialog-content-wrapper" fxFlex="1 1 0" fxLayout="column" fxLayoutAlign="start stretch">
  <mat-toolbar class="mat-accent m-0" fxFlex="none">
    <mat-toolbar-row fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Oops... We have a problem.</span>
      <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div mat-dialog-content fusePerfectScrollbar class="p-24 pb-0 m-0" fxLayout="column">
    <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="start start" class="mb-16">
      <div class="announcement">
        There was some sort of error.
        We're working on it.
        Please try again later.
      </div>
    </div>
    <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="start start" class="mb-16">
      <img [src]="imageSoure" class="s-100 errorImage"/>
    </div>
    <div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="start start" class="mb-16">
      <mat-expansion-panel class="mb-16 ml-24 w-90-p">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Details
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="details">
          {{errorString}}
        </div>
      </mat-expansion-panel>
      </div>
    </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxFlex="none">
    <button mat-raised-button color="accent" (click)="matDialogRef.close()" aria-label="Ok">Ok</button>
  </div>
</div>
