import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { Title } from '@angular/platform-browser';
import { Floorplan } from 'app/types/floorplan/floorplan';
import { TitleRelease } from 'app/types/shared/titleRelease';
import { TitleWorkHistory } from 'app/types/floorplan/TitleWorkHistory';
import { TitleExposureDetails } from 'app/types/shared/titleExposure/titleExposureDetails';
import { TitleLocationHistory } from 'app/types/floorplan/titleLocationHistory';
import { TitleReleaseHistory } from 'app/types/shared/titleReleaseHistory';
import { CancelTitleReleaseRequest } from 'app/pages/floorplan/flooring-view/cancel-title-release-dialog/cancel-title-release-Request';
import { FloorplanOverrideRuleRequest } from 'app/pages/floorplan/floorplan-title-new/floorplan-override-rules-dialog/floorplan-override-rule-Request';
import { TitleReleaseCancelResponse } from 'app/types/shared/titleReleaseCancelResponse';
import { FloorplanOverrideShipTitleRequest } from 'app/pages/floorplan/floorplan-title-new/floorplan-override-ship-title-dialog/floorplanOverrideShipTitleRequest';
import { FloorplanOverrideShipTitleResponse } from 'app/types/shared/floorplanOverrideShipTitleResponse';

@Injectable({
  providedIn: 'root'
})
export class TitleService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/title';
  }

  save(title: Title): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}`, title);
  }

  saveTitleLocationHistory(titleLocationHistory: TitleLocationHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/titleLocationHistory`, titleLocationHistory);
  }

  create(title: Title): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/create`, title);
  }

  createTitleRelease(titleRelease: TitleRelease): Observable<Response<TitleRelease[]>> {
    return this._http.post<Response<TitleRelease[]>>(`${environment.apiUrl}${this.path}/releaseTitle`, titleRelease);
  }

  floorplanOverrideShipTitle(floorplanOverrideShipTitleRequest: FloorplanOverrideShipTitleRequest): Observable<Response<FloorplanOverrideShipTitleResponse>> {
    return this._http.post<Response<FloorplanOverrideShipTitleResponse>>(`${environment.apiUrl}${this.path}/override-floorplan-ship-title`, floorplanOverrideShipTitleRequest);
  }

  cancelTitleRelease(cancelTitleReleaseRequest: CancelTitleReleaseRequest): Observable<Response<TitleReleaseCancelResponse>> {
    return this._http.put<Response<TitleReleaseCancelResponse>>(`${environment.apiUrl}${this.path}/cancelReleaseTitle`, cancelTitleReleaseRequest);
  }

  overrideCancelTitleRelease(cancelTitleReleaseRequest: CancelTitleReleaseRequest): Observable<Response<TitleReleaseCancelResponse>> {
    return this._http.put<Response<TitleReleaseCancelResponse>>(`${environment.apiUrl}${this.path}/overrideCancelReleaseTitle`, cancelTitleReleaseRequest);
  }

  saveTitleWorkHistory(titleWorkHistory: TitleWorkHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/titleWorkHistory`, titleWorkHistory);
  }

  saveTitleWorkComplete(titleWorkHistory: TitleWorkHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/titleWorkComplete`, titleWorkHistory);
  }

  saveTitleWorkCanceled(titleWorkHistory: TitleWorkHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/title-work-canceled`, titleWorkHistory);
  }

  saveTitleWorkNeeded(titleWorkHistory: TitleWorkHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/titleWorkNeeded`, titleWorkHistory);
  }

  getTitleExposureForDealer(dealerId: string): Observable<TitleExposureDetails> {
    return this._http.get<TitleExposureDetails>(`${environment.apiUrl}${this.path}/get-title-exposure-for-dealer/${dealerId}`);
  }

  shipTitle(titleRelease: TitleReleaseHistory): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/ship-title`, titleRelease);
  }

  markTitleAsMissing(titleId: string): Observable<Response<TitleLocationHistory>> {
    return this._http.put<Response<TitleLocationHistory>>(`${environment.apiUrl}${this.path}/${titleId}/mark-title-as-missing`, titleId);
  }

  unmarkTitleAsMissing(titleId: string): Observable<Response<TitleLocationHistory>> {
    return this._http.put<Response<TitleLocationHistory>>(`${environment.apiUrl}${this.path}/${titleId}/unmark-title-as-missing`, titleId);
  }

  tryToOverrideExposureRule(floorplanOverrideRuleRequest: FloorplanOverrideRuleRequest): Observable<Response<TitleRelease>> {
    return this._http.put<Response<TitleRelease>>(`${environment.apiUrl}${this.path}/try-to-override-rule`, floorplanOverrideRuleRequest);
  }
}
