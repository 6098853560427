import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class AzureGraphService {
  private readonly _path = '/azureGraph';

  constructor(private _http: HttpClient) {}

  mergeAzureToDarkhorse(): Observable<Response<boolean>> {
    return this._http.post<Response<boolean>>(`${environment.apiUrl}${this._path}/MergeAzureToDarkhorse`, {});
  }

  getUserJobTitleByEmail(email: string): Observable<Response<string>> {
    return this._http.get<Response<string>>(`${environment.apiUrl}${this._path}/getUserJobTitleByEmail/${email}`);
  }
}
