import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { LoggingLevel } from '@coastal/enums/loggingLevel';
import { ApplicationInsightsEvent } from '@coastal/types/applicationInsightsEvent';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  _loggingLevel: LoggingLevel = LoggingLevel.Verbose;
  private appInsights: ApplicationInsights;

  public properties: any = {};

  public initialize(applicationName: string, environment: any): void {
    this.properties.application = applicationName;
    this.properties.build = environment.buildNumber;

    this._loggingLevel = environment.loggingLevel;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  log(message: any, level = LoggingLevel.Warnings, properties?: any) {
    this.properties['loggingLevel'] = level;
    if (this.shouldLog(level)) {
      const allProperties = { ...properties, ...this.properties };

      switch (level) {
        case LoggingLevel.Errors:
          // eslint-disable-next-line no-console
          console.error(message);
          this.logException(message, allProperties);

          break;

        case LoggingLevel.Warnings:
          // eslint-disable-next-line no-console
          console.warn(message);
          break;

        case LoggingLevel.Info:
          // eslint-disable-next-line no-console
          console.info(message);
          break;

        default:
          // eslint-disable-next-line no-console
          console.debug(message);
      }
    }
  }

  private shouldLog(level: LoggingLevel) {
    if (this._loggingLevel === LoggingLevel.None) {
      return false;
    } else if (this._loggingLevel === LoggingLevel.Errors) {
      return level === LoggingLevel.Errors;
    } else if (this._loggingLevel === LoggingLevel.Warnings) {
      return level === LoggingLevel.Errors || level === LoggingLevel.Warnings;
    } else if (this._loggingLevel === LoggingLevel.Info) {
      return level === LoggingLevel.Errors || level === LoggingLevel.Warnings || level === LoggingLevel.Info;
    } else {
      return true;
    }
  }

  error(message: Error, properties?: any) {
    this.log(message, LoggingLevel.Errors, properties);
  }

  warning(message: any) {
    this.log(message, LoggingLevel.Warnings);
  }

  info(message: any) {
    this.log(message, LoggingLevel.Info);
  }

  verbose(message: any) {
    this.log(message, LoggingLevel.Verbose);
  }

  debug(message: any) {
    this.log(message, LoggingLevel.Verbose);
  }

  public trackEvent(event: ApplicationInsightsEvent, properties?: any) {
    this.info(`Tracking event: ${event.name}`);
    const allProperties = { ...properties, ...this.properties };
    this.appInsights.trackEvent({ name: event.name }, allProperties);
  }

  private logException(exception: Error, properties?: any) {
    this.appInsights.trackException({ exception, properties });
  }
}
