import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@environment';
import { ErrorDialogComponent } from 'app/components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  dialogRef: any;

  constructor(private _matDialog: MatDialog) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry((request.method === 'GET' ? 1 : 0)),
        catchError((error: HttpErrorResponse) => {
          // There shouldn't be multiple dialogs.
          if (this.dialogRef) return throwError(error);

          const suppressErrorDialog = request.headers.has('SuppressErrorDialog')
          // We explicitly do not care about bad requests with the auto token endpoint as they auto resolve
          // and are handled by the refreshDarkhorse Token service
           || (error.status === 400 && request.url.toLowerCase().includes(environment.AuthConfig.tokenEndpoint));

          if (!suppressErrorDialog) {
            this.dialogRef = this._matDialog.open(ErrorDialogComponent, {
              disableClose: true,
              panelClass: 'dh-error-dialog',
              data: {
                error
              }
            });
            this.dialogRef.afterClosed().subscribe(() => {
              this.dialogRef = null;
            });
          }

          return throwError(error);
        })
      );
  }
}
