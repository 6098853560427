import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { Bookkeeping } from 'app/types/accounting/bookkeeping/bookkeeping';
import { ReceivableFee } from 'app/types/accounting/bookkeeping/receivableFee';
import { PaymentOptionsRequest } from 'app/types/accounting/bookkeeping/paymentOptionsRequest';
import { Payment } from 'app/types/accounting/bookkeeping/payment';

@Injectable({
  providedIn: 'root'
})
export class BookkeepingService {

  private readonly path = '/bookkeeping';
  constructor(private _http: HttpClient) {

   }

  GetBookkeeping(key: string): Observable<Response<Bookkeeping>> {
    return this._http.get<Response<Bookkeeping>>(`${environment.apiUrl}${this.path}?key=${key}`);
  }

  GetBookkeepingPayment(request: PaymentOptionsRequest): Observable<Response<Payment>> {
    return this._http.post<Response<Payment>>(`${environment.apiUrl}${this.path}/get-payment`, request);
  }

  MakeBookkeepingPayment(payment: Payment): Observable<Response<Bookkeeping>> {
    return this._http.post<Response<Bookkeeping>>(`${environment.apiUrl}${this.path}/make-payment`, payment);
  }

  UpsertFee(request: ReceivableFee): Observable<Response<Bookkeeping>> {
    return this._http.post<Response<Bookkeeping>>(`${environment.apiUrl}${this.path}/add-or-update-fee`,request);
  }

}
