import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { SupportsFiltering } from '@coastal/types/supportsFiltering';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { DocumentType } from 'app/types/document-management/document-type/document-type';
import { PermissionWithUser } from 'app/types/security/user-model/permissionWithUser';
import { documentTypeKeyValueResponse } from 'app/types/document-management/document/document-type-key-value-response';
import { DocumentTypeRequest } from 'app/types/document-management/document-type/document-type-request';
import { DocumentTypeUpdateRequest } from 'app/types/document-management/document-type/document-type-update-request';


@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService extends BreadcrumbService {

  readonly path = '/document-type';

  constructor(private _http: HttpClient) {
    super();
  }

  getById(id: string): Observable<Response<DocumentType>> {
    return this._http.get<Response<DocumentType>>(`${environment.apiUrl}${this.path}/${id}`);
  }

   search(request: PagedSearchRequest | SupportsFiltering): Observable<Response<PagedSearchResults<DocumentType>>> {
    return this._http.post<Response<PagedSearchResults<DocumentType>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  inactivateAsync(type: string): Observable<Response<boolean>> {
    return this._http.post<Response<boolean>>(`${environment.apiUrl}${this.path}/inactivate/${type}`, null);
  }

  getPermissionsByUserIdAsync(): Observable<Response<PermissionWithUser[]>> {
    return this._http.get<Response<PermissionWithUser[]>>(`${environment.apiUrl}${this.path}/permissions`);
  }

  getDocumentTypeKeyValueByComponentAsync(componentCalling: string): Observable<documentTypeKeyValueResponse[]> {
    return this._http.get<documentTypeKeyValueResponse[]>(`${environment.apiUrl}${this.path}/valuePairs/${componentCalling}`);
  }

  addDocumentType(documentType: DocumentTypeRequest): Observable<Response<DocumentType[]>> {
    return this._http.post<Response<DocumentType[]>>(`${environment.apiUrl}${this.path}`, documentType);
  }

  updateDocumentType(documentType: DocumentTypeUpdateRequest): Observable<Response<DocumentType[]>> {
    return this._http.put<Response<DocumentType[]>>(`${environment.apiUrl}${this.path}`, documentType);
  }

  checkForDuplicatTypeAsync(type: string): Observable<Response<boolean>> {
    return this._http.post<Response<boolean>>(`${environment.apiUrl}${this.path}/check-duplicate`, {type: type});
  }
}
