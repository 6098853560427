import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { RiskReportRequest } from 'app/types/contact-to-business/asset-report/riskReportRequest';
import { ReportRequest } from 'app/types/contact-to-business/asset-report/reportRequest';
import { Observable } from 'rxjs';
import { PdfReportUrl } from 'app/types/contact-to-business/asset-report/pdfReportUrl';

@Injectable({
  providedIn: 'root'
})

export class AssetReportService {
constructor(private _http: HttpClient) {}

  requestRiskReport(request: RiskReportRequest): Observable<ReportRequest> {
    return this._http.post<ReportRequest>(`${environment.assetReportApiUrl}/report-request`, request);
  }

  lookupReportRequests(dealerId: string): Observable<ReportRequest[]> {
    let params = new HttpParams();
    params = params.append('dealerId', dealerId);

    return this._http.get<ReportRequest[]>(`${environment.assetReportApiUrl}/lookup-report-requests`, { params: params });
  }

  downloadReportUrl(reportRequestId: string, dealerNumber: number, username: string): Observable<PdfReportUrl> {
    let params = new HttpParams();
    params = params.append('reportRequestId', reportRequestId);
    params = params.append('dealerNumber', dealerNumber.toString());
    params = params.append('username', username);

    return this._http.get<PdfReportUrl>(`${environment.assetReportApiUrl}/download-pdf-report`, { params: params });
  }
}
