import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { getNavigation } from 'app/navigation';
import { ApplicationService } from 'app/services/application/application.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggingService } from '@coastal';
import { ApiService } from 'app/services/api/api.service';
import { SecurityService } from 'app/services/application/security/security.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { SignalRService } from 'app/services/application/signalR/signal-r.service';

const GRAPH_PHOTO_48x48_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value';
const URL = window.URL || window.webkitURL;
@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  webpageUsers: Array<string>;

  isBusy = this._applicationService.isBusy;
  userName = '';
  profileImageBlobUrl: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  isAuthenticated: boolean;

  showProfilePic = false;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   * @param { ApplicationService } _applicationService
   * @param { MsalService } _authService
   * @param { HttpClient } _httpClient
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _applicationService: ApplicationService,
    private _httpClient: HttpClient,
    private _domSanitizer: DomSanitizer,
    private _loggingService: LoggingService,
    private _apiService: ApiService,
    private _securityService: SecurityService,
    private _authService: OAuthService,
    public signalRService: SignalRService
  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];


    this.navigation = getNavigation(this._applicationService.security);

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  async ngOnInit(): Promise<void> {

    this.setProfileImage();

    this.userName = this._securityService.userName;

    this.setupWebpageUsersSubscription();

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang: { id: string; }): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this._apiService.user.logout(this.userName).toPromise().then(result => {
      if (result && result.data) {
        this._loggingService.info(this.userName + ' has been removed from cache.');
      }
    });
    this._authService.logOut();
  }

  setProfileImage(): void {
    const headers = new HttpHeaders()
      .set('SuppressErrorDialog', 'true');

    const options = {
      headers,
      responseType: 'blob' as const
    };

    this._httpClient.get(GRAPH_PHOTO_48x48_ENDPOINT, options).toPromise()
      .then(e => {
        this.profileImageBlobUrl = this._domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e).toString());
        this.showProfilePic = true;
      })
      .catch();
  }

 private setupWebpageUsersSubscription() {
    this.signalRService.webpageUsers.subscribe((data: string[]) => {
      this.webpageUsers = data;
    });
 }
}
