import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { VehicleDetailDrillDownRequest } from 'app/types/shared/vehicleDetailDrillDownRequest';
import { VehicleDetailDrillDown } from 'app/types/shared/vehicleDetailDrillDown';

@Injectable({
  providedIn: 'root'
})
export class VehicleDetailDrillDownService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/vehicleDetailDrillDown';
  }

  getVehicleDetailDrillDownAsync(vehicleDetailDrillDownRequest: VehicleDetailDrillDownRequest): Observable<Response<VehicleDetailDrillDown>> {
     return this._http.post<Response<VehicleDetailDrillDown>>(`${environment.apiUrl}${this.path}/vehicle-detail-drilldown`, vehicleDetailDrillDownRequest);
  }
}
