import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dealer } from 'app/types/dealer/dealer';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { StopStartAutoAchRequest } from 'app/types/accounting/auto-ach/stopStartAutoAchRequest';

@Injectable({
  providedIn: 'root'
})
export class AutoAchService {
  private readonly path = '/autoach';

  constructor(private _http: HttpClient) {
  }

  stopDealerAutoAch(request: StopStartAutoAchRequest): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/stopAutoAch`, request);
  }

  startDealerAutoAch(request: StopStartAutoAchRequest): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/startAutoAch`, request);
  }
}
