import { Injectable } from '@angular/core';
import { ApiService } from 'app/services/api/api.service';
import { LoggingService } from '@coastal';
import { User } from 'app/types/security/user-model/user';
import { OAuthService } from 'angular-oauth2-oidc';
import { FeatureFlagService } from 'app/services/api/featureFlag/feature-flag.service';
@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  currentUser: User;
  isEnabledDocumentSetupModule = false;
  constructor(
    private _apiService: ApiService,
    private _loggingService: LoggingService,
    private _oauthService: OAuthService,
    private _featureFlagService: FeatureFlagService,
  ) { }

  hasPermission = (name: string) => !!this.currentUser?.permissions?.find(permission => permission.name === name);
  hasRole = (name: string) => !!this.currentUser?.roles?.find(role => role.name === name || role.name === 'Administrator');

  async initialize() {
    this._loggingService.debug('Loading user information...');
    const userName = this.email;
    const response = await this._apiService.user.get(userName).toPromise()
      .then(r => {
        this.currentUser = r.data;
        this._loggingService.properties.username = this.currentUser.userName;
        return Promise.resolve();
      });

      await this._featureFlagService.isFeatureFlagEnabledAsync('DocumentSetup').toPromise().then(status =>{
        this.isEnabledDocumentSetupModule = status;
      });

    return response;
  }

  get userName(): string {
    const claims = this._oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['name'];
  }

  get email(): string {
    const claims = this._oauthService.getIdentityClaims();
    if (!claims) return null;
    return claims['email'];
  }

  get isEnabledDocumentSetup(): boolean {
    return this.isEnabledDocumentSetupModule;
  }
}
