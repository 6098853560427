import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'dh-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorDialogComponent {
  error: HttpErrorResponse;
  errorString: string;
  imageSoure = '/assets/images/grumpy-cat.png';

  constructor(
    public matDialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: HttpErrorResponse,
  ) {
    this.error = _data.error;

    if (this.error.error instanceof ErrorEvent) {
      this.errorString = `Error: ${this.error.error.message}`;
    } else {
      this.imageSoure = `https://httpcats.com/${this.error.status}.jpg`;
      this.errorString = `Error Code: ${this.error.status}\nMessage: ${this.error.message}`;
    }
  }
}
