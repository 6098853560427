import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TermPlan } from 'app/types/termplan/termPlan';

import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';

import { environment } from '@environment';
import { map } from 'rxjs/operators';
import { UtilityService } from '@coastal';
import { SupportsFiltering } from '@coastal/types/supportsFiltering';
import { TermPlanToEntityRelationship } from 'app/types/termplan/termPlanToEntityRelationship';

@Injectable({
  providedIn: 'root'
})
export class TermPlanService {
  private readonly _path = '/termplan';

  constructor(private _http: HttpClient) { }

  search(request: PagedSearchRequest | SupportsFiltering): Observable<Response<PagedSearchResults<TermPlan>>> {
    return this._http.post<Response<PagedSearchResults<TermPlan>>>(`${environment.apiUrl}${this._path}/search`, request);
  }

  add(termPlan: TermPlan): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/add`, termPlan);
  }

  edit(termPlan: TermPlan): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/edit`, termPlan);
  }

  inactivate(termPlanId: string): Observable<Response<TermPlan>> {
    return this._http.get<Response<TermPlan>>(`${environment.apiUrl}${this._path}/${termPlanId}/inactivate`);
  }

  activate(termPlanId: string): Observable<Response<TermPlan>> {
    return this._http.get<Response<TermPlan>>(`${environment.apiUrl}${this._path}/${termPlanId}/activate`);
  }

  get(termPlanId: string): Observable<Response<TermPlan>> {
    return this._http.get<Response<TermPlan>>(`${environment.apiUrl}${this._path}/${termPlanId}`)
      .pipe(
        map(response => {
          // Sort periods
          if (response.data?.periods) {
            response.data.periods = response.data.periods.sort((a, b) => UtilityService.compare(a.periodNumber, b.periodNumber, true));
          }
          return response;
        })
      );
  }

  getTermPlanByNumber(termPlanNumber: number): Observable<Response<TermPlan>> {
    return this._http.get<Response<TermPlan>>(`${environment.apiUrl}${this._path}/termPlanByNumber/${termPlanNumber}`);
  }

  getRelationship(relationship: TermPlanToEntityRelationship): Observable<Response<any>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/get-relationship`, relationship);
  }

  addDealerToTermPlan(section: string, dealerId: string, termPlanId: string, startDate: Date): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/add-dealer`, { section, id: dealerId, termPlanId, startDate });
  }

  addAuctionToTermPlan(section: string, auctionId: string, termPlanId: string, startDate: Date): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/add-auction`, { section, id: auctionId, termPlanId, startDate });
  }

  removeDealerFromTermPlan(section: string, dealerId: string, termPlanId: string, endDate: Date): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/remove-dealer`, { section, id: dealerId, termPlanId, endDate });
  }

  removeAuctionFromTermPlan(section: string, auctionId: string, termPlanId: string, endDate: Date): Observable<Response<TermPlan>> {
    return this._http.post<Response<TermPlan>>(`${environment.apiUrl}${this._path}/remove-auction`, { section, id: auctionId, termPlanId, endDate });
  }

  delete(termPlanId: string): Observable<Response<any>> {
    return this._http.delete<Response<any>>(`${environment.apiUrl}${this._path}/${termPlanId}/delete`);
  }
}
