import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { VehicleColor } from 'app/types/shared/vehicleColor';
import { TitleReleaseAddress } from 'app/types/shared/titleReleaseAddress';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  path: string;

  constructor(private _http: HttpClient) {
    this.path = '/shared';
  }

  getAllVehicleColorsAsync(): Observable<Response<VehicleColor[]>> {
    return this._http.get <Response<VehicleColor[]>>(`${environment.apiUrl}${this.path}/getAllVehicleColors`);
  }
}
