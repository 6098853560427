import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@coastal';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const routeAuthorized: boolean = true;
const appRoutes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
  },

  {
    path: 'accounting',
    loadChildren: () => import('./pages/accounting/accounting-routing.module').then(m => m.AccountingRoutingModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'dealers',
    loadChildren: () => import('./pages/dealer/dealer.module').then(m => m.DealerModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'auctions',
    loadChildren: () => import('./pages/auction/auction.module').then(m => m.AuctionModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'vendors',
    loadChildren: () => import('./pages/vendors/vendors.module').then(m => m.VendorsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then(m => m.ContactsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'floorplan',
    loadChildren: () => import('./pages/floorplan/floorplan.module').then(m => m.FloorplanModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'titles-queue',
    loadChildren: () => import('./pages/titles-queue/titles-queue.module').then(m => m.TitlesQueueModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'title-release-business-addresses',
    loadChildren: () => import('./pages/title-release-business-addresses/title-release-business-addresses.module').then(m => m.TitleReleaseBusinessAddressesModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'myqueue',
    loadChildren: () => import('./pages/myqueue/myqueue.module').then(m => m.MyQueueModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'administration',
    loadChildren: () => import('./pages/administration/administration-routing.module').then(m => m.AdministrationRoutingModule),
    canActivate: [AuthGuard]
  },

  {
    path: 'components',
    loadChildren: () => import('app/components/components-routing.module').then(m => m.ComponentsRoutesRoutingModule),
    canActivate: [AuthGuard]
  },

  {path: 'rll', loadChildren: ()=> import('./pages/routingListenerBlankPage/routingListenerBlankPage.module').then(m => m.RoutingListenerBlankPageModule)
    },

  // Default
  { path: '**', redirectTo: 'welcome' }
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor() { }
}
