import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { ChangeLog } from 'app/types/shared/changeLog';

@Injectable({
  providedIn: 'root'
})
export class ChangeLogService {
  readonly _path = '/changeLog';

  constructor(private _http: HttpClient) {}

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<ChangeLog>>> {
    return this._http.post<Response<PagedSearchResults<ChangeLog>>>(`${environment.apiUrl}${this._path}`, request);
  }
}
