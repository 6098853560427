import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Location } from 'app/types/shared/location';
import { BusinessRecordExists } from 'app/types/shared/businessRecordExists';
import { LocationPhoneNumberRequest } from 'app/types/shared/locationPhoneNumberRequest';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  readonly path = '/locations';

  constructor(private _http: HttpClient) { }

  update(location: Location): Observable<Response<Location>> {
    return this._http.put<Response<Location>>(`${environment.apiUrl}${this.path}/update`, location);
  }

  getLocation(locationId: string): Observable<Response<Location>> {
    return this._http.get<Response<Location>>(`${environment.apiUrl}${this.path}/${locationId}`);
  }

  terminateDealerLocation(locationId: string): Observable<Response<Location>> {
    return this._http.delete<Response<Location>>(`${environment.apiUrl}${this.path}/terminate/dealer/${locationId}`);
  }

  terminateAuctionLocation(locationId: string): Observable<Response<Location>> {
    return this._http.delete<Response<Location>>(`${environment.apiUrl}${this.path}/terminate/auction/${locationId}`);
  }

  getAuctionLocations(auctionId: string): Observable<Response<Location[]>> {
    return this._http.get<Response<Location[]>>(`${environment.apiUrl}${this.path}/auction/${auctionId}`);
  }

  addLocationToAuction(location: Location, auctionId: string): Observable<Response<Location>> {
    return this._http.post<Response<Location>>(`${environment.apiUrl}${this.path}/auction/${auctionId}`, location);
  }

  getDealerLocations(dealerId: string): Observable<Response<Location[]>> {
    return this._http.get<Response<Location[]>>(`${environment.apiUrl}${this.path}/dealer/${dealerId}`);
  }

  checkDuplicatePhoneNumberExists(locationPhoneNumberRequest: LocationPhoneNumberRequest): Observable<Response<BusinessRecordExists>> {
    return this._http.post<Response<BusinessRecordExists>>(`${environment.apiUrl}${this.path}/businessRecordExists/checkDuplicatePhoneNumber`, locationPhoneNumberRequest);
  }
}
