import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FloorplanSearchResult } from '@coastal/types/floorplan/floorplanSearchResult';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { EditCollateralRequest } from 'app/pages/floorplan/collateral/editCollateralRequest';
import { FloorplanApproval } from 'app/types/floorplan/floorplanApproval';
import { DeclineFloorplanRequest } from 'app/pages/floorplan/flooring-view/decline-floorplan-dialog/declineFloorplanRequest';
import { RevokeDeclineFloorplanRequest } from 'app/pages/floorplan/flooring-view/revoke-decline-floorplan-dialog/revokeDeclineFloorplanRequest';
import { ExtendFinancingRequest } from 'app/pages/floorplan/flooring-financing/extend-financing-dialog/extendFinancingRequest';
import { TermPlan } from 'app/types/termplan/termPlan';
import { Floorplan } from 'app/types/floorplan/floorplan';
import { VehicleStatus } from 'app/types/floorplan/vehicleStatus';
import { VoidFloorplanRequest } from 'app/types/floorplan/voidFloorplanRequest';
import { UndoExtendFinancingRequest } from 'app/pages/floorplan/flooring-financing/undoExtendFinancingRequest';
import { EditFloorplanPayToRequest } from 'app/types/floorplan/editFloorplanPayToRequest';
import { EditFinancingRequest } from 'app/pages/floorplan/flooring-financing/edit-financing-dialog/editFinancingRequest';
import { DeferCurtailmentRequest } from 'app/pages/floorplan/flooring-financing/defer-curtailment-dialog/deferCurtailmentRequest';
import { VinHistoryResponse } from '@coastal/types/floorplan/vinHistoryResponse';
import { TitleShipment } from 'app/types/floorplan/titleShipment';
import { GetFinancingInformationResponse } from 'app/types/floorplan/getFinancingInformationResponse';
import { UpdateFloorplanProfileRequest } from 'app/types/floorplan/updateFloorplanProfileRequest';
import { ReleaseTrackingNumber } from 'app/types/shared/releaseTrackingNumber';
import { TitleFileFolder } from 'app/types/floorplan/titleFileFolder';
import { TitleRecord } from 'app/types/floorplan/titleRecord';
import { ExtensionMaturityRefloor } from 'app/types/floorplan/extensionMaturityRefloor';
import { CurtailmentDeferralEligibility } from 'app/types/floorplan/curtailmentDeferralEligibility';
import { FloorplanNote } from 'app/types/shared/floorplanNote';
import { FloorplanUafRefundSearch } from 'app/types/floorplan/floorplanUafRefundSearch';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/floorplan';
  }

  searchAsync(
    request: PagedSearchRequest
  ): Observable<Response<PagedSearchResults<FloorplanSearchResult>>> {
    return this._http.post<Response<PagedSearchResults<FloorplanSearchResult>>>(
      `${environment.apiUrl}${this.path}/search`,
      request
    );
  }

  addFloorplanAsync(
    request: Floorplan
  ): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(
      `${environment.apiUrl}${this.path}/addfloorplan`,
      request
    );
  }

  checkVinHistoryExistenceAsync(vin: string, excludedFloorplanCollateralId?: string): Observable<Response<VinHistoryResponse>> {
    const request = { vin, excludedFloorplanCollateralId: excludedFloorplanCollateralId || null };
    return this._http.post<Response<VinHistoryResponse>>(`${environment.apiUrl}${this.path}/vinHistoryExists`, request);
  }

  checkFloorplanIsUnderDisbursementProcessing(floorplanId: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(`${environment.apiUrl}${this.path}/is-disbursement-process-running/${floorplanId}`);
  }

  updateFloorplanAsync(
    request: Floorplan
  ): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(
      `${environment.apiUrl}${this.path}/updatefloorplan`,
      request
    );
  }

  updateFloorplanProfileAsync(
    request: UpdateFloorplanProfileRequest
  ): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(
      `${environment.apiUrl}${this.path}/updateFloorplanProfile`,
      request
    );
  }

  updateTitleShipmentAsync(
    request: TitleShipment
  ): Observable<Response<TitleShipment>> {
    return this._http.post<Response<TitleShipment>>(
      `${environment.apiUrl}${this.path}/updateTitleShipment`,
      request
    );
  }

  updateVehicleStatusAsync(
    request: VehicleStatus
  ): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(
      `${environment.apiUrl}${this.path}/updateVehicleStatus`,
      request
    );
  }

  updateReleaseTrackingNumberAsync(
    request: ReleaseTrackingNumber
  ): Observable<Response<ReleaseTrackingNumber>> {
    return this._http.post<Response<ReleaseTrackingNumber>>(
      `${environment.apiUrl}${this.path}/update-title-release-tracking-number`,
      request
    );
  }
  updateTitleFileFolderAsync(
    request: TitleFileFolder
  ): Observable<Response<TitleFileFolder>> {
    return this._http.post<Response<TitleFileFolder>>(
      `${environment.apiUrl}${this.path}/updateTitleFileFolder`,
      request
    );
  }
  getTitleRecordsAsync(
    id: string
  ): Observable<Response<TitleRecord[]>> {
    return this._http.get<Response<TitleRecord[]>>(`${environment.apiUrl}${this.path}/TitleRecords/${id}`);
  }
  getFloorplan(id: string): Observable<Response<Floorplan>> {
    return this._http.get<Response<Floorplan>>(`${environment.apiUrl}${this.path}/${id}`, {headers: {'Cache-Control': 'no-cache'}});
  }

  editCollateral(request: EditCollateralRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/collateral/edit`, request);
  }

  editInsuranceDates(request: EditCollateralRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/collateral/editInsuranceDates`, request);
  }

  editOverrideValuationUsed(request: EditCollateralRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/collateral/override`, request);
  }

  reverseOverrideValuationUsed(request: EditCollateralRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/collateral/override/reverse`, request);
  }

  approveFloorplan(approval: FloorplanApproval): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/approve`, approval);
  }

  overrideAndApproveFloorplan(approval: FloorplanApproval): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/override`, approval);
  }

  declineFloorplan(request: DeclineFloorplanRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/decline`, request);
  }

  overrideCreditLimitAndApprove(approval: FloorplanApproval): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/override/creditlimit`, approval);
  }

  revokeDeclineFloorplan(request: RevokeDeclineFloorplanRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/revoke-decline`, request);
  }

  extendFinancing(request: ExtendFinancingRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/extend-financing`, request);
  }

  getExtendedTermPlansByFloorplanIdAsync(floorplanId: string): Observable<Response<TermPlan[]>> {
    return this._http.get<Response<TermPlan[]>>(`${environment.apiUrl}${this.path}/${floorplanId}/get-extended-term-plans`);
  }

  getFinancingInformationAsync(floorplanId: string, dealerId: string): Observable<GetFinancingInformationResponse> {
    return this._http.get<GetFinancingInformationResponse>(`${environment.apiUrl}${this.path}/${floorplanId}/${dealerId}/get-financing-information`);
  }

  voidFloorplan(voidFloorplanRequest: VoidFloorplanRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/void`, voidFloorplanRequest);
  }

  canUndoExtendFloorplanAsync(floorplanId: string, termPlanId: string, termPlanSequenceNumber: number): Observable<Response<boolean>> {
    const request = { floorplanId, termPlanId, termPlanSequenceNumber };
    return this._http.post<Response<boolean>>(`${environment.apiUrl}${this.path}/can-undo-extend-floorplan`, request);
  }

  undoExtendFinancing(request: UndoExtendFinancingRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/undo-extend-financing`, request);
  }

  getRegularTermPlansByFloorplanIdAsync(floorplanId: string): Observable<Response<TermPlan[]>> {
    return this._http.get<Response<TermPlan[]>>(`${environment.apiUrl}${this.path}/${floorplanId}/get-regular-term-plans`);
  }

  editFinancingAsync(request: EditFinancingRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/edit-financing`, request);
  }

  updateFloorplanPayToAsync(request: EditFloorplanPayToRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/updateFloorplanPayTo`, request);
  }

  deferCurtailmentAsync(request: DeferCurtailmentRequest): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/defer-curtailment`, request);
  }

  runFloorplanRules(floorplanId: string): Observable<Response<Floorplan>> {
    return this._http.get<Response<Floorplan>>(`${environment.apiUrl}${this.path}/rules/${floorplanId}`);
  }

  updateFloorplanCollateralFromRequestBlackBookMatchingErrorDialog(floorplanId: string): Observable<Response<Floorplan>> {
    const request = { floorplanId };
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this.path}/update-floorplan-collateral-request-black-book-matching-error-dialog`, request);
  }

  runValuation(floorplanId: string): Promise<Floorplan> {
    return this._http.post<Floorplan>(`${environment.apiUrl}${this.path}/collateral/run-valuation/${floorplanId}`, {}).toPromise();
  }

  sendPreReceiptToMulberry(floorplanId: string): Promise<Floorplan> {
    return this._http.post<Floorplan>(`${environment.apiUrl}${this.path}/send-pre-receipt-to-mulberry/${floorplanId}`, {}).toPromise();
  }

  removeFloatTerms(floorplanId: string): Observable<TermPlan[]> {
    return this._http.post<TermPlan[]>(`${environment.apiUrl}${this.path}/${floorplanId}/removeFloatTerms`, {});
  }
  downloadPDF(programName): any {
    return this._http.get(
      `${environment.apiUrl}/floorplan/get-titlefile-by-recordid/${programName}`,
      { responseType: 'blob', observe: 'response' }
    );
  }
  updateExtensionMaturityRefloorAsync(request: ExtensionMaturityRefloor): Observable<Response<ExtensionMaturityRefloor>> {
    return this._http.post<Response<ExtensionMaturityRefloor>>(
      `${environment.apiUrl}${this.path}/updateExtensionMaturityRefloorAsync`,
      request
    );
  }
  getFloorplanNotesAsync(
    id: string
  ): Observable<Response<FloorplanNote[]>> {
    return this._http.get<Response<FloorplanNote[]>>(`${environment.apiUrl}${this.path}/floorplanNotes/${id}`);
  }

  deleteFloorplanNoteAsync(floorplanNoteId: string): Observable<Response<boolean>> {
    return this._http.delete<Response<boolean>>(`${environment.apiUrl}${this.path}/${floorplanNoteId}/delete`);
  }

  addFloorplanNoteAsync(
    request: FloorplanNote
  ): Observable<Response<FloorplanNote>> {
    return this._http.post<Response<FloorplanNote>>(
      `${environment.apiUrl}${this.path}/addFloorplanNote`,
      request
    );
  }

  doesApproveActionNeedToBeDisabledAsync(floorplanId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/does-approve-action-need-to-be-disabled/${floorplanId}`);
  }

  doesFloorplanHaveFpp(floorplanId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/does-floorplan-have-fpp/${floorplanId}`);
  }

  doesFloorplanHaveFps(floorplanId: string): Observable<boolean> {
    return this._http.get<boolean>(`${environment.apiUrl}${this.path}/does-floorplan-have-fps/${floorplanId}`);
  }

  resendFpp(floorplanId: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(`${environment.apiUrl}${this.path}/resend-fpp/${floorplanId}`);
  }

  resendFps(floorplanId: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(`${environment.apiUrl}${this.path}/resend-fps/${floorplanId}`);
  }

  getFloorplanCurtailmentEligibility(floorplanId: string): Observable<Response<CurtailmentDeferralEligibility>> {
    return this._http.get<Response<CurtailmentDeferralEligibility>>(`${environment.apiUrl}${this.path}/get-curtailment-eligibility/${floorplanId}`);
  }

  getOneDayLoanTotal(floorplanId: string): Observable<Response<number>> {
    return this._http.get<Response<number>>(`${environment.apiUrl}${this.path}/get-one-day-loan-total/${floorplanId}`);
  }

  getEquityOrOneDayLoanTotal(floorplanId: string): Observable<Response<number>> {
    return this._http.get<Response<number>>(`${environment.apiUrl}${this.path}/get-equity-total/${floorplanId}`);
  }

  getFloorplansForFloorplanSelectionQuery(query: string, dealerId: string): Observable<Response<FloorplanUafRefundSearch[]>> {
    return this._http.get<Response<FloorplanUafRefundSearch[]>>(`${environment.apiUrl}${this.path}/floorplanAccountingUafRefund/${query}`, { params: { dealerId }});
  }
}
