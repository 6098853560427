import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Floorplan } from 'app/types/floorplan/floorplan';

@Injectable({
  providedIn: 'root'
})
export class AccountingAdministrationService {
  readonly _path = '/accounting-administration';

  constructor(private _http: HttpClient) {}

  recalculateInterest(floorplanId: string): Observable<Response<Floorplan>> {
    return this._http.post<Response<Floorplan>>(`${environment.apiUrl}${this._path}/recalculate-interest/${floorplanId}`, {});
  }
}
