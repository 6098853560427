
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { PaymentMethod } from 'app/types/shared/paymentMethod';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService extends BreadcrumbService {
  private readonly path = '/paymentMethod';

  constructor(private _http: HttpClient) {
    super();
  }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<PaymentMethod>>> {
    return this._http.post<Response<PagedSearchResults<PaymentMethod>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  add(paymentMethod: PaymentMethod): Observable<Response<PaymentMethod>> {
    return this._http.post<Response<PaymentMethod>>(`${environment.apiUrl}${this.path}/add`, paymentMethod);
  }

  edit(paymentMethod: PaymentMethod): Observable<Response<PaymentMethod>> {
    return this._http.post<Response<PaymentMethod>>(`${environment.apiUrl}${this.path}/edit`, paymentMethod);
  }

  getPaymentMethod(paymentMethodId: string): Observable<Response<PaymentMethod>> {
    return this._http.get<Response<PaymentMethod>>(`${environment.apiUrl}${this.path}/${paymentMethodId}`);
  }
}
