import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ApplicationService } from './application/application.service';

@Injectable()
export class BusyInterceptor implements HttpInterceptor {
    constructor(private applicationService: ApplicationService) { }

    activeRequests = 0;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.activeRequests++;
        this.applicationService.setIsBusy(true);
        return next.handle(req).pipe(
            finalize(() => {
                this.activeRequests--;
                this.applicationService.setIsBusy(this.activeRequests > 0);
            })
        );
    }
}
