import { Injectable } from '@angular/core';
import { AccountingService } from './accounting/accounting.service';
import { DealersService } from './dealers/dealers-service.service';
import { AdministrationService } from './administration/administration.service';
import { SettingsService } from './settings/settings.service';
import { LocationsService } from './locations/locations-service.service';
import { UserService } from './users/user.service';
import { BankingService } from './banking/banking-service.service';
import { AzureGraphService } from './azureGraph/azureGraph.service';
import { InsuranceService } from './insurance/insurance-service.service';
import { ChangeLogService } from './changeLog/change-log-service.service';
import { FloorplanService } from './floorplan/floorplan.service';
import { SpecificSourceService } from './specificSource/specific-source.service';
import { SharedService } from './shared/shared.service';
import { TitleService } from './title/title.service';
import { TransactionHistoryService } from './transactionHistory/transactionHistory.service';
import { UnappliedFundsHistoryService } from './unappliedFunds/unappliedFundsHistory.service';
import { PaymentsService } from './payment/payments-service.service';
import { MarketService } from './dealers/market.service';
import { ExportService } from './export/export-service.service';
import { AccountingAdministrationService } from './administration/accounting/accouting-administration.service';
import { VinLookupService } from './integrations/vinLookup.service';
import { VehicleDetailDrillDownService } from './integrations/vehicleDetailDrillDown.service';
import { AuditService } from './dealers/lot-audit/audit.service';
import { FloorplanAuditService } from './floorplan/lot-audit/audit.service';
import { TitleSearchService } from './title/title-search.service';
import { TitleReleaseAddressService } from './titleReleaseAddress/title-release-address.service';
import { BannerService } from './banner/banner.service';
import { DocumentTypeService } from './document-manager/document-type/document-type-service.service';
import { AssetReportService } from './contacts-to-business/asset-report.service'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private _accountingService: AccountingService,
    private _dealersService: DealersService,
    private _administrationService: AdministrationService,
    private _settingsService: SettingsService,
    private _locationsService: LocationsService,
    private _userService: UserService,
    private _bankingService: BankingService,
    private _insuranceService: InsuranceService,
    private _azureGraphService: AzureGraphService,
    private _changeLogService: ChangeLogService,
    private _floorplanService: FloorplanService,
    private _specificSourceService: SpecificSourceService,
    private _sharedService: SharedService,
    private _titleService: TitleService,
    private _transactionHistoryService: TransactionHistoryService,
    private _unappliedFundsHistoryService: UnappliedFundsHistoryService,
    private _paymentService: PaymentsService,
    private _marketService: MarketService,
    private _exportService: ExportService,
    private _vinLookupService: VinLookupService,
    private _vehicleDetailDrillDownService: VehicleDetailDrillDownService,
    private _accountingAdministrationService: AccountingAdministrationService,
    private _auditService: AuditService,
    private _floorplanAuditService: FloorplanAuditService,
    private _titleSearchService: TitleSearchService,
    private _titleReleaseAddressService: TitleReleaseAddressService,
    private _bannerService: BannerService,
    private _documentTypeService: DocumentTypeService,
    private _assetReportService: AssetReportService
  ) { }

  accounting = this._accountingService;
  dealers = this._dealersService;
  market = this._marketService;
  administration = this._administrationService;
  settings = this._settingsService;
  locations = this._locationsService;
  insurance = this._insuranceService;
  user = this._userService;
  banking = this._bankingService;
  azureGraph = this._azureGraphService;
  changeLog = this._changeLogService;
  floorplan = this._floorplanService;
  specificSource = this._specificSourceService;
  shared = this._sharedService;
  titleService = this._titleService;
  titleSearchService = this._titleSearchService;
  transactionHistoryService = this._transactionHistoryService;
  unappliedFundsHistoryService= this._unappliedFundsHistoryService;
  paymentService = this._paymentService;
  exportService = this._exportService;
  vinLookupService = this._vinLookupService;
  vehicleDetailDrillDownService = this._vehicleDetailDrillDownService;
  accountingAdministrationService = this._accountingAdministrationService;
  auditService = this._auditService;
  floorplanAuditService = this._floorplanAuditService;
  titleReleaseAddressService = this._titleReleaseAddressService;
  bannerService = this._bannerService;
  documentTypeService = this._documentTypeService;
  assetReportService = this._assetReportService;
}
