import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../types/address';

@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {
  transform(address: Address, useLineBreaks = false): string {
    if (address) {
      if (useLineBreaks)
        return `${address.addressLine1}\n ${address.addressLine2 ? address.addressLine2 + '\n' : ''} ${address.city}, ${address.stateId} ${address.postalCode}`;

     return `${address.addressLine1}, ${address.addressLine2 ? address.addressLine2 + ',' : ''} ${address.city}, ${address.stateId} ${address.postalCode}`;
    }
     return '';
  }
}
