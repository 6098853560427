import { AbstractControl, ValidationErrors, Validators, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable, timer, of  } from 'rxjs';
import { switchMap, map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BusinessType } from 'app/types/contact-to-business/businessType';
import { LocationsService } from 'app/services/api/locations/locations-service.service';
import { LocationPhoneNumberRequest } from 'app/types/shared/locationPhoneNumberRequest';

@Injectable()
export class LocationExistsValidator {

  constructor(private locationService: LocationsService) {}

  CreateValidator(locationValidatorData) {
    return (control: AbstractControl): Observable<ValidationErrors>  => {
      if (!control.valueChanges || control.pristine || !control.value) {
        return of( null );
      } else {
        return timer(500)
        .pipe(filter(C => control.value && !control.pristine))
        .pipe(switchMap(() => {
          const locationPhoneNumberRequest: LocationPhoneNumberRequest = {
            businessId : locationValidatorData.businessId,
            businessType : locationValidatorData.bussinesType,
            phoneNumber: control.value
          };
          return this.locationService
            .checkDuplicatePhoneNumberExists(locationPhoneNumberRequest)
            .pipe(map(result =>
              (result.data && result.data.originalBusinessId ? {
              isDuplicate: result.data.isDuplicate
              ,originalBusinessNumber: BusinessType.Dealer.businessTypeId === locationValidatorData.bussinesType?'D' + result.data.originalBusinessNumber: 'A' + result.data.originalBusinessNumber
              } : null
            )));
        }));
      }
    };
  }
}
