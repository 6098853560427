import { Injectable } from '@angular/core';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Role } from 'app/types/security/user-model/role';
import { UpdatePermissionsForRole } from 'app/types/security/user-model/updatePermissionsForRole';
import { UpdateSecurityGroupsForRole } from 'app/types/security/user-model/updateSecurityGroupsForRole';
import { UpdateUsersForRole } from 'app/types/security/user-model/updateUsersForRole';

@Injectable({
  providedIn: 'root',
})
export class UserManagementSelectionRolesService {
  readonly path = '/role';

  constructor(private _http: HttpClient) { }

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<Role>>> {
    return this._http.post<Response<PagedSearchResults<Role>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  getRole(roleId: string): Observable<Response<Role>> {
    return this._http.get<Response<Role>>(`${environment.apiUrl}${this.path}/detail/` + roleId);
  }

  saveRole(role: Role): Observable<Response<Role>> {
    return this._http.post<Response<Role>>(`${environment.apiUrl}${this.path}`, role );
  }

  getAllRoles(): Observable<Response<Role[]>> {
    return this._http.get<Response<Role[]>>(`${environment.apiUrl}${this.path}/getall/`);
  }

  savePermissionsForRole(updatePermissionsForRole: UpdatePermissionsForRole): Observable<Response<Role>> {
    return this._http.post<Response<Role>>(`${environment.apiUrl}/role/updatePermissions/`, updatePermissionsForRole);
  }

  saveSecurityGroupsForRole(updateSecurityGroupsForRole: UpdateSecurityGroupsForRole): Observable<Response<Role>> {
    return this._http.post<Response<Role>>(`${environment.apiUrl}/role/updateSecurityGroups/`, updateSecurityGroupsForRole);
  }

  saveUsersForRole(updateUsersForSecurityGroup: UpdateUsersForRole): Observable<Response<Role>> {
    return this._http.post<Response<Role>>(`${environment.apiUrl}${this.path}/updateUsers/`, updateUsersForSecurityGroup);
  }
}
