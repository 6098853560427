import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PagedSearchRequest} from '@coastal/types/pagedSearchRequest';
import {PagedSearchResults} from '@coastal/types/pagedSearchResults';
import {Observable} from 'rxjs';
import {environment} from '@environment';
import {Response} from '@coastal/types/response';
import {NewDealerDetails} from 'app/types/dealer/newDealerDetails';
import {Dealer} from 'app/types/dealer/dealer';
import {BreadcrumbService} from 'app/services/application/breadcrumb/breadcrumb.service';
import {Location} from 'app/types/shared/location';
import {SupportsFiltering} from '@coastal/types/supportsFiltering';
import {FloorplanPartyForSelection} from 'app/pages/floorplan/floorplanPartyForSelection';
import {DealerSearchRow} from 'app/types/dealer/dealerSearchRow';
import {DealerLock} from 'app/types/dealer/dealerLock';
import {DealerLockReason} from 'app/types/dealer/dealerLockReason';
import {DealerDuplicateSearchResult} from 'app/types/dealer/dealerDuplicateSearchResult';
import {DealerProgram} from 'app/types/dealer/dealerProgram';
import {TermPlanToEntityRelationship} from 'app/types/termplan/termPlanToEntityRelationship';
import {UnappliedFundsRefundRequest} from 'app/types/dealer/unappliedFundsRefundRequest';
import {DealerStatus} from 'app/types/dealer/dealerStatus';
import {DealerReserve} from 'app/types/dealer/dealerReserve';
import {DealerNote} from 'app/types/shared/dealerNote';
import {TerminationReason} from 'app/types/shared/terminationReason';
import {UpdateDealerAnnualReviewDueDateRequest} from 'app/types/dealer/updateDealerAnnualReviewDueDateRequest';
import {BusinessRecordExists} from 'app/types/shared/businessRecordExists';
import {DocumentStockNumberExists} from 'app/types/document-management/document/document-stock-number-exists';
import { AccountingUnappliedFundsRefundRequest } from 'app/types/dealer/accountingUnappliedFundsRefundRequest';

@Injectable({
  providedIn: 'root'
})
export class DealersService extends BreadcrumbService {
  readonly path = '/dealers';

  constructor(private _http: HttpClient) {
    super();
  }

  search(request: PagedSearchRequest | SupportsFiltering): Observable<Response<PagedSearchResults<DealerSearchRow>>> {
    return this._http.post<Response<PagedSearchResults<DealerSearchRow>>>(`${environment.apiUrl}${this.path}/search`, request);
  }

  addDealer(details: NewDealerDetails): Observable<Response<string>> {
    return this._http.post<Response<string>>(`${environment.apiUrl}${this.path}/add`, details);
  }

  addLock(dealerLock: DealerLock): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/add-lock`, dealerLock);
  }

  removeLock(dealerLock: DealerLock): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/remove-lock`, dealerLock);
  }

  checkForDuplicates(details: NewDealerDetails): Observable<Response<DealerDuplicateSearchResult>> {
    return this._http.post<Response<DealerDuplicateSearchResult>>(`${environment.apiUrl}${this.path}/duplicates`, details);
  }

  getDealer(id: string): Observable<Response<Dealer>> {
    return this._http.get<Response<Dealer>>(`${environment.apiUrl}${this.path}/${id}`);
  }

  getDealerLockReasons(): Observable<Response<DealerLockReason[]>> {
    return this._http.get<Response<DealerLockReason[]>>(`${environment.apiUrl}${this.path}/dealerLockReasons`);
  }

  getDealerStatusOptionsBasedOnCurrentStatus(dealerId: string): Observable<Response<DealerStatus[]>> {
    return this._http.get<Response<DealerStatus[]>>(`${environment.apiUrl}${this.path}/statusOptionsByCurrentStatus/${dealerId}`);
  }

  updateDealer(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update`, dealer);
  }

  updateInsuranceThreshold(dealerId: string, insuranceThreshold: number): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update-insurance-threshold`, {dealerId, insuranceThreshold});
  }

  updateDealerProfile(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update/profile`, dealer);
  }

  dealerWithTaxIdExists(taxId: string, dealerId: number): Observable<Response<BusinessRecordExists>> {
    let params = new HttpParams();
    params = params.append('taxId', taxId);
    params = params.append('dealerId', dealerId.toString());
    return this._http.get<Response<BusinessRecordExists>>(`${environment.apiUrl}${this.path}/taxId/exists`, {params: params});
  }

  addLocationToDealer(location: Location, dealerId: string): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/location/${dealerId}`, location);
  }

  updateDealerLocation(location: Location, dealerId: string): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/location/${dealerId}`, location);
  }

  getDealerPrograms(): Observable<Response<DealerProgram[]>> {
    return this._http.get<Response<DealerProgram[]>>(`${environment.apiUrl}${this.path}/programs`);
  }

  getDealersForFloorplanPartySelection(): Observable<Response<FloorplanPartyForSelection[]>> {
    return this._http.get<Response<FloorplanPartyForSelection[]>>(`${environment.apiUrl}${this.path}/getDealersForFloorplanSelection`);
  }

  setDefaultTermPlan(relationship: TermPlanToEntityRelationship): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/set-default-term-plan`, relationship);
  }

  setDealerDefaulted(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/setDefaulted`, dealer);
  }

  removeDealerDefaulted(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/removeDefaulted`, dealer);
  }

  requestRefund(request: UnappliedFundsRefundRequest): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/refund`, request);
  }

  accountingRequestRefund(request: AccountingUnappliedFundsRefundRequest): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/accounting-refund`, request);
  }

  chargeOffDealer(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/chargeOff`, dealer);
  }

  writeOffDealer(dealer: Dealer): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/writeOff`, dealer);
  }

  getDealerFullSocialSecurityNumber(dealerId: string): Observable<Response<string>> {
    return this._http.get<Response<string>>(`${environment.apiUrl}${this.path}/identifiers/${dealerId}`);
  }

  getDealerFicoScore(dealerId: string): Observable<Response<string>> {
    return this._http.get<Response<string>>(`${environment.apiUrl}${this.path}/fico/${dealerId}`);
  }

  updateAreUnappliedFundsFrozen(areUnappliedFundsFrozen: boolean, dealerId: string): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/update/frozen`, {areUnappliedFundsFrozen, dealerId});
  }

  UpdateDealerOptIn(isKAAOptIn: boolean, dealerId: string): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update/optin`, {isKAAOptIn, dealerId});
  }

  ReactivateDealer(dealerId: string): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/${dealerId}/reactivate-dealer`, {});
  }

  TerminatedToProspect(dealerId: string): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/${dealerId}/terminated-to-prospect`, {});
  }

  UpdateDealerTitleSettings(isDealerOwnsTitleWork: boolean, dealerId: string): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update/titleSettings`, {isDealerOwnsTitleWork, dealerId});
  }

  UpdateDealerTitleExposureSettings(titleExposureThreshold: number, dealerId: string): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/update/titleExposureSettings`, {titleExposureThreshold, dealerId});
  }

  addDealerReserve(dealerReserve: DealerReserve): Observable<Response<Dealer>> {
    return this._http.post<Response<Dealer>>(`${environment.apiUrl}${this.path}/addDealerReserve`, dealerReserve);
  }

  GetDealerReserves(dealerId: string): Observable<Response<DealerReserve[]>> {
    return this._http.get<Response<DealerReserve[]>>(`${environment.apiUrl}${this.path}/GetDealerReservesByDealerId/${dealerId}`);
  }

  inactivateDealerReserveById(dealerReserve: DealerReserve): Observable<Response<DealerReserve>> {
    return this._http.post<Response<DealerReserve>>(`${environment.apiUrl}${this.path}/InactivateDealerReserveByDealerReserveId`, dealerReserve);
  }

  getDealerNotesAsync(dealerId: string): Observable<Response<DealerNote[]>> {
    return this._http.get<Response<DealerNote[]>>(`${environment.apiUrl}${this.path}/GetDealerNotesByDealerId/${dealerId}`);
  }

  addDealerNoteAsync(request: DealerNote): Observable<Response<DealerNote>> {
    return this._http.post<Response<DealerNote>>(`${environment.apiUrl}${this.path}/addDealerNote`, request);
  }

  deleteDealerNoteAsync(dealerNoteId: string): Observable<Response<boolean>> {
    return this._http.delete<Response<boolean>>(`${environment.apiUrl}${this.path}/${dealerNoteId}/delete`);
  }

  getDealerTerminationReasons(): Observable<Response<TerminationReason[]>> {
    return this._http.get<Response<TerminationReason[]>>(`${environment.apiUrl}${this.path}/GetDealerTerminationReasons`);
  }

  updateAnnualReviewDueDate(updateDealerAnnualReviewDueDateRequest: UpdateDealerAnnualReviewDueDateRequest): Observable<Response<Dealer>> {
    return this._http.put<Response<Dealer>>(`${environment.apiUrl}${this.path}/updateAnnualReviewDueDate`, updateDealerAnnualReviewDueDateRequest);
  }

  getDealerLocationsById(dealerId: string): Observable<Response<Location[]>> {
    return this._http.get<Response<Location[]>>(`${environment.apiUrl}${this.path}/GetDealerLocationsById/${dealerId}`);
  }

  checkIfAuctionAccessIdExists(auctionAccessId: string): Observable<Response<boolean>> {
    return this._http.get<Response<boolean>>(`${environment.apiUrl}${this.path}/checkIfAuctionAccessIdExists/${auctionAccessId}`);
  }

  checkIfStockNumberExists(dealerId: string, stockNumber: number): Observable<Response<DocumentStockNumberExists>> {
    return this._http.get<Response<DocumentStockNumberExists>>(`${environment.apiUrl}${this.path}/dealer/${dealerId}/stock/${stockNumber}/exists`);
  }

  getDealersForFloorplanSelectionQuery(query: string): Observable<Response<FloorplanPartyForSelection[]>> {
    return this._http.get<Response<FloorplanPartyForSelection[]>>(`${environment.apiUrl}${this.path}/dealersForFloorplanSelectionQuery/${query}`);
  }
  getDealerForUAFRefund(dealerId: string): Observable<Response<FloorplanPartyForSelection[]>> {
    return this._http.get<Response<FloorplanPartyForSelection[]>>(`${environment.apiUrl}${this.path}/dealerForUAFRefund/${dealerId}`);
  }
}
