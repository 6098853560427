import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Response } from '@coastal/types/response';
import { environment } from '@environment';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { GeneralLedgerAccountType } from 'app/types/accounting/general-ledger/GeneralLedgerAccountType';

@Injectable({
  providedIn: 'root'
})
export class GeneralLedgerAccountTypeService {
  private readonly _path = '/generalledgeraccounttype';

  constructor(private _http: HttpClient) {}

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<GeneralLedgerAccountType>>> {
    return this._http.post<Response<PagedSearchResults<GeneralLedgerAccountType>>>(`${environment.apiUrl}${this._path}/search`, request);
  }

  edit(generalLedgerAccountType: GeneralLedgerAccountType): Observable<Response<GeneralLedgerAccountType>> {
    return this._http.post<Response<GeneralLedgerAccountType>>(`${environment.apiUrl}${this._path}/edit`, generalLedgerAccountType);
  }

  add(generalLedgerAccountType: GeneralLedgerAccountType): Observable<Response<GeneralLedgerAccountType>> {
    return this._http.post<Response<GeneralLedgerAccountType>>(`${environment.apiUrl}${this._path}/add`, generalLedgerAccountType);
  }

  getAll(): Observable<Response<PagedSearchResults<GeneralLedgerAccountType>>> {
    const request: PagedSearchRequest = {
      searchText: '',
      orderBy: 'name',
      orderByDescending: false
    };

    return this.search(request);
  }
}
