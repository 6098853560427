import { Injectable } from '@angular/core';
import { UserManagementSelectionUsersService } from './user-management/user-management-selection-users/user-management-selection-users.service';
import { UserManagementSelectionGroupsService } from './user-management/user-management-selection-groups/user-management-selection-groups.service';
import { UserManagementSelectionRolesService } from './user-management/user-management-selection-roles/user-management-selection-roles.service';
import { UserManagementSelectionPermissionsService } from './user-management/user-management-selection-permissions/user-management-selection-permissions.service';
import { SecuredPartyService } from './securedParty/secured-party.service';

@Injectable({
  providedIn: 'root',
})

export class AdministrationService {
  public userManagementSelectionUsersService: UserManagementSelectionUsersService;
  public userManagementSelectionGroupsService: UserManagementSelectionGroupsService;
  public userManagementSelectionRolesService: UserManagementSelectionRolesService;
  public userManagementSelectionPermissionsService: UserManagementSelectionPermissionsService;
  public securedPartyService: SecuredPartyService;

  constructor (private _userManagementSelectionUsersService: UserManagementSelectionUsersService,
      private _userManagementSelectionGroupsService: UserManagementSelectionGroupsService,
      private _userManagementSelectionRolesService: UserManagementSelectionRolesService,
      private _userManagementSelectionPermissionsService: UserManagementSelectionPermissionsService,
      private _securedPartyService: SecuredPartyService) {
    this.userManagementSelectionUsersService = this._userManagementSelectionUsersService;
    this.userManagementSelectionGroupsService = this._userManagementSelectionGroupsService;
    this.userManagementSelectionRolesService = this._userManagementSelectionRolesService;
    this.userManagementSelectionPermissionsService = this._userManagementSelectionPermissionsService;
    this.securedPartyService = this._securedPartyService;
   }
}
