import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { Response } from '@coastal/types/response';
import { Banking } from 'app/types/shared/banking';
import { BankRoutingNumber } from 'app/types/shared/bankRoutingNumber';
import { BankAccountNumbers } from 'app/types/shared/bankAccountNumbers';

@Injectable({
  providedIn: 'root'
})
export class BankingService {
  readonly _path = '/banking';

  constructor(private _http: HttpClient) {}

  addDealerBanking(banking: Banking): Observable<Response<Banking>> {
    return this._http.post<Response<Banking>>(`${environment.apiUrl}${this._path}/dealer/add`, banking);
  }

  editDealerBanking(banking: Banking): Observable<Response<Banking>> {
    return this._http.post<Response<Banking>>(`${environment.apiUrl}${this._path}/dealer/edit`, banking);
  }

  addAuctionBanking(banking: Banking): Observable<Response<Banking>> {
    return this._http.post<Response<Banking>>(`${environment.apiUrl}${this._path}/auction/add`, banking);
  }

  editAuctionBanking(banking: Banking): Observable<Response<Banking>> {
    return this._http.post<Response<Banking>>(`${environment.apiUrl}${this._path}/auction/edit`, banking);
  }

  getBankRoutingNumber(bankRoutingNumber: string): Observable<Response<BankRoutingNumber>> {
    return this._http.get<Response<BankRoutingNumber>>(`${environment.apiUrl}${this._path}/getBankRoutingNumber/${bankRoutingNumber}`);
  }

  getAuctionBankingAccountNumbers(auctionId: string): Observable<Response<BankAccountNumbers>> {
    return this._http.get<Response<BankAccountNumbers>>(`${environment.apiUrl}${this._path}/accountNumbers/auction/${auctionId}`);
  }

  getDealerBankingAccountNumbers(dealerId: string): Observable<Response<BankAccountNumbers>> {
    return this._http.get<Response<BankAccountNumbers>>(`${environment.apiUrl}${this._path}/accountNumbers/dealer/${dealerId}`);
  }
}
