export class ApplicationInsightsEvent {
  name: string;

  public static readonly paymentSubmitted: ApplicationInsightsEvent = {
    name: 'Client Payment Submitted'
  };

  public static readonly login: ApplicationInsightsEvent = {
    name: 'Client Login'
  };

  public static readonly updateAccounting: ApplicationInsightsEvent = {
    name: 'Client Update Accounting'
  };

  public static readonly updateFloorplanTaskCompletedEventReceived: ApplicationInsightsEvent = {
    name: 'Client Update Floorplan Task Completed Event Received'
  };

  public static readonly paymentCompletedMessageEventReceived: ApplicationInsightsEvent = {
    name: 'Client Payment Completed Message Event Received'
  };

  public static readonly addFloorplanMessageEventReceived: ApplicationInsightsEvent = {
    name: 'Client Add Floorplan Event Received'
  }

}
