import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Response } from '@coastal/types/response';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { BreadcrumbService } from 'app/services/application/breadcrumb/breadcrumb.service';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { TitleSearchResult } from 'app/types/titles/titleSearchResult';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';

@Injectable({
  providedIn: 'root'
})
export class TitleSearchService extends BreadcrumbService {
  path: string;

  constructor(private _http: HttpClient) {
    super();
    this.path = '/TitleSearch';
  }

  searchAsync(
    request: PagedSearchRequest
  ): Observable<Response<PagedSearchResults<TitleSearchResult>>> {
    return this._http.post<Response<PagedSearchResults<TitleSearchResult>>>(
      `${environment.apiUrl}${this.path}/search`,
      request
    );
  }

}
