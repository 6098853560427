import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Action } from 'app/types/action';
import { LoggingService } from '@coastal';
import { Router, NavigationStart } from '@angular/router';
import { ApplicationService } from 'app/services/application/application.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'dh-actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent implements OnInit {
  actions: Action[];

  constructor(
    private _loggingService: LoggingService,
    private _router: Router,
    private _applicationService: ApplicationService,
    private _changeDetectionRef: ChangeDetectorRef
  ) {

    // Reset actions when navigating.
    this._router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(() => {
        this._applicationService.actionsMenu.setActions([]);
      });
  }

  ngOnInit(): void {
    this._applicationService.actionsMenu.actions.subscribe(actions => {
      this.actions = actions;
      this._changeDetectionRef.detectChanges();
    });
  }

  addActivity() {
    this._loggingService.debug('Adding an activity.');
  }

  addTask() {
    this._loggingService.debug('Adding a task.');
  }
}
