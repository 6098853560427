import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedSearchRequest } from '@coastal/types/pagedSearchRequest';
import { Response } from '@coastal/types/response';
import { PagedSearchResults } from '@coastal/types/pagedSearchResults';
import { environment } from '@environment';
import { GeneralLedgerAccountPlaceholder } from 'app/types/accounting/general-ledger/generalLedgerAccountPlaceholder';

@Injectable({
  providedIn: 'root'
})
export class GeneralLedgerAccountPlaceholderService {
  private readonly _path = '/generalledgeraccountplaceholder';

  constructor(private _http: HttpClient) {}

  search(request: PagedSearchRequest): Observable<Response<PagedSearchResults<GeneralLedgerAccountPlaceholder>>> {
    return this._http.post<Response<PagedSearchResults<GeneralLedgerAccountPlaceholder>>>(`${environment.apiUrl}${this._path}/search`, request);
  }

  edit(generalLedgerPlaceholder: GeneralLedgerAccountPlaceholder): Observable<Response<GeneralLedgerAccountPlaceholder>> {
    return this._http.post<Response<GeneralLedgerAccountPlaceholder>>(`${environment.apiUrl}${this._path}/edit`, generalLedgerPlaceholder);
  }

  add(generalLedgerPlaceholder: GeneralLedgerAccountPlaceholder): Observable<Response<GeneralLedgerAccountPlaceholder>> {
    return this._http.post<Response<GeneralLedgerAccountPlaceholder>>(`${environment.apiUrl}${this._path}/add`, generalLedgerPlaceholder);
  }

  getAll(): Observable<Response<PagedSearchResults<GeneralLedgerAccountPlaceholder>>> {
    const request: PagedSearchRequest = {
      searchText: '',
      orderBy: 'name',
      orderByDescending: false
    };

    return this.search(request);
  }
}
