<mat-toolbar class="p-0 mat-elevation-z1">

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')"
        fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <dh-actions-menu></dh-actions-menu>
    </div>

    <div class="" fxFlex fxFlex fxLayout="row" fxLayoutAlign="end center">

      <mat-spinner color="accent" diameter="36" strokeWidth="7" *ngIf="isBusy | async"></mat-spinner>

      <div *ngIf="this.signalRService.showWebpageUsers">
        <button mat-button [matMenuTriggerFor]="usersMenu" class="users-button">
          <div class="custom-size-and-alignment" fxLayout="row" fxLayoutAlign="center center">
            <img class="avatar mr-0 mr-sm-16" src="/assets/images/crowd.png" >
            <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
          </div>
        </button>
          <mat-menu #usersMenu="matMenu" [overlapTrigger]="false">
          <mat-chip-list>
            <mat-chip *ngFor="let webpageUser of this.webpageUsers">
                {{webpageUser.split("@")[0]}}
            </mat-chip>
          </mat-chip-list>
        </mat-menu>
      </div>

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" [src]="this.profileImageBlobUrl" *ngIf="showProfilePic">
          <img class="avatar mr-0 mr-sm-16" src="/assets/images/generic-profile.png" *ngIf="!showProfilePic">
          <span>{{this.userName}}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>

  </div>

</mat-toolbar>
