import { Component, Input } from '@angular/core';
import { ApplicationService } from 'app/services/application/application.service';

@Component({
  selector: 'dh-busy-overlay',
  templateUrl: './busy-overlay.component.html',
  styleUrls: ['./busy-overlay.component.scss']
})
export class BusyOverlayComponent {

  isBusy = this._applicationService.isBusy;
  @Input() showSpinner = false;

  constructor(
    private _applicationService: ApplicationService
  ) { }

}
