import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'app/services/application/settings/settings.service';
import { SecurityService } from './security/security.service';
import { ActionsMenuService } from './actions-menu/actions-menu.service';
import { EventsService } from './events/events.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  security: SecurityService;
  settings: SettingsService;
  actionsMenu: ActionsMenuService;
  events: EventsService;

  constructor(
    private _actionsMenuService: ActionsMenuService,
    private _securityService: SecurityService,
    private _settingsService: SettingsService,
    private _eventsService: EventsService
  ) {
    this.security = _securityService;
    this.settings = _settingsService;
    this.actionsMenu = _actionsMenuService;
    this.events = _eventsService;
  }

  isBusy = new BehaviorSubject<boolean>(false);

  setIsBusy(newStatus: boolean) {
    setTimeout(() => {
      this.isBusy.next(newStatus);
    });
  }

  initialize() {
    return Promise.all([
      this.security.initialize(),
      this.events.initialize(),
      this.settings.initialize(),
    ]);
  }
}
